<template>
    <div>
        <!-- -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/main">
                        <i class="fas fa-home"></i>Inicio
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/usuarios">Tabla usuario</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">usuario</li>
            </ol>
        </nav>
        <!-- -->
        {{ JSON.stringify(state.form0) }}
        <!-- -->
        <div>
            <legend style="text-align:center;"><strong>Formulario de trámite</strong></legend>
            <div class="form-group" id="picture" style="text-align: center;">

            </div>
        </div>
        <br>
        <div class="row ">
            
            <div class="col-md-12" style="display: flex;align-items: center;justify-content: center;">
                <div class="card mb-3 col-md-7">
                    <div class="card-header bg-info">
                        Datos 
                    </div>
                    <div class="card-body">
                        <!---------------------------------------------------->
                        <form id="form0" >
                            <!-- ---------------------------------------------- -->

                            <div id="divres"></div>

                            <!--------------------------->
                            <div class="form-group row">
                                <label for="id_u" class="col-sm-3 col-form-label">Cod. Tra.</label>
                                <div class="col-md-3">
                                    <input  name="id_u" 
                                            id="id_u" 
                                            type="text" 
                                            class="form-control" 
                                            v-model="state.form0.id_tramite"  
                                            readonly disabled>
                                </div>

                                <label for="nro_doc" class="col-md-2 col-form-label">Fecha:<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-4">
                                    <input 
                                        name='nro_doc' 
                                        type='date' 
                                        class='form-control' 
                                        v-model="state.form0.fecha_ini" 
                                    />
                                </div>

                            </div>
                            <!-----------2---------------->
                            <div class="form-group row">
                                <label for="nro_doc" class="col-sm-3 col-form-label">Cliente<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                  <div class="input-group">
                                      <input 
                                          name='nro_doc' 
                                          type='text' 
                                          class='form-control' 
                                          v-model="state.form0.cliente" 
                                      />
                                      <button class="input-group-text " 
                                                @click="btn_search_click">
                                                <i class="fa fa-search"></i>
                                      </button>
                                  
                                      <button class="input-group-text " 
                                                @click="btn_add_click">
                                                <i class="fa fa-plus "></i>
                                      </button>
                                  </div>
                                </div>
                                
                            </div>
                            <!-------------8-------------->
                            <!-------------7-------------->
                            <div class="form-group row">
                                
                            </div>
                            <!-------------8-------------->
                            <!--------------------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label"> Descripción:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                  <textarea
                                    name="detallet"
                                    type="text"
                                    class="form-control"
                                    rows="2"
                                    v-model = "state.form0.detallet"
                                  ></textarea>
                                </div>
                            </div>

                            <!------------6--------------->
                            <!--<div class="form-group row">
                                    <label for="fecha_naci" class="col-sm-3 col-form-label">Fecha de nacimiento:</label>
                                    <div class="col-sm-9">
                                        <input name="fecha_naci" id="fecha_naci"  class="form-control"
                                        type="text" value="" placeholder="dd/mm/yy">
                                    </div>
                                </div>-->

                            <!-------------9--------------->
                            <!--<div class="form-group row">
                                    <label for="email" class="col-sm-3 col-form-label">Email:</label>
                                    <div class="col-sm-9">
                                        <input name="email" id="email" type="text" value="" class="form-control"
                                        >
                                    </div>
                                </div>-->
                            <!-------------10--------------->
                            
                            <div class="form-group row">
                                <label for="username" class="col-sm-3 col-form-label">Estado:<span
                                        class="text-danger"></span></label>
                                <div class="col-sm-4">
                                  <NuDropDown
                                      name="total" 
                                      :options = "state.estados"
                                      v-model = "state.form0.estado"
                                      @onChange ="handleChangeDrop"
                                  />

                                </div>
                            </div>
                            <!-------------10--------------->
                            <div class="top_title2">
                                <h4 class=""><span>Cuentas</span></h4>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="tel" class="col-sm-3 col-form-label">Costo:</label>
                                <div class="col-sm-4">
                                    <div class="input-group">
                                      <input 
                                        name='costo' 
                                        type='text' 
                                        class='form-control text-end' 
                                        v-model="state.form0.costo" 
                                      />
                                      <span class="input-group-text " >
                                        &nbsp;<i class="fa fa-usd"></i>&nbsp;
                                      </span>
                                    </div>
                                </div>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="tel" class="col-sm-3 col-form-label">Total:</label>
                                <div class="col-sm-4">
                                  <div class="input-group">
                                    <input 
                                      name='total' 
                                      type='text' 
                                      class='form-control text-end' 
                                      v-model="state.form0.total" 
                                    />
                                    <span class="input-group-text " >
                                          <!--<i class="fa fa-usd"></i>-->
                                          Bs.
                                      </span>
                                    </div>
                                </div>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="tel" class="col-sm-3 col-form-label">Saldo:</label>
                                <div class="col-sm-4">
                                    
                                    <input 
                                      name='saldo' 
                                      type='text' 
                                      class='form-control text-end' 
                                      v-model="state.form0.saldo" 
                                      readonly
                                      disabled
                                    />
                                </div>
                            </div>
                            
                            <!-------------10--------------->
                  

                            <!------------------------------------------------------------->
                            <div class="form-group">
                                <div class="text-center">
                                    <button id="btn_save" type="button" class="btn btn-info" @click="aceptar_click">Guardar Datos</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
            <!-- -->
            <div class="row">
                <div class="col-md-12">
                    <NuAlert ref="nuaRef" />
                </div>
            </div>
        </div>
    </div>

    <BoxBuscarCliente ref="boxClienteRef"
            @okModal="handleOkBox"
    />

    <ClienteModal2 
      ref="clienteModalRef"
      @okModal="handleOkBoxCli"
    >
    </ClienteModal2>

    <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de guardar?"
      icon="fa fa-question-circle"
      @confirm="handleConfirm">
    </NuConfirm>

</template>

<script lang="ts">
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado


import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 
import { useRoute } from 'vue-router';
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 
import NuAlert from '@/components/nubox/NuAlert.vue';
import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";
import BoxBuscarCliente from '@/pages/_box/BoxBuscarCliente.vue'; //~@ 
import ClienteModal2 from '@/pages/mantenimiento/cliente/ClienteModal2.vue';


const initialForm={
  idd:0,
  ope:'n',
  
	id_tramite:0,
  fecha_ini:'',
  detallet:'',
  costo:0,
  total:0,
  saldo:0,
  id_u_agente :0,
  id_cliente:1,
  cliente:'',
  estado:1,
}

export default {
  components: {
    NuConfirm,
    NuAlert,
    ImageUploadSingle,
    NuDropDown,
    BoxBuscarCliente,
    ClienteModal2,
  },
  props: {},
  setup(props:any, context:any){
    const nuaRef = ref(null); //puntero al compoente
    const route = useRoute(); //variables de ruta
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente
    const boxClienteRef = ref<InstanceType<typeof BoxBuscarCliente>>();//puntero al componente
    const clienteModalRef = ref<InstanceType<typeof ClienteModal2>>()

    const IMAGE_API_URL = ref(process.env.VUE_APP_API_URL + "image_uploadPhoto");
    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "afiles/");

    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      roles:[],
      cajas:[],
      //selected_estado:0,
      estados:[ { value:1, label: "Recibido",color:"#008ffb"  },
                { value:2, label: "Derivado", color:"#ce9320" },
                { value:3, label: "Observado", color:"#ce9320" },
                { value:4, label: "Finalizado", color:"#ce9320" },
              ],
    });

    onMounted(()=>{
      
      //console.log(route.params.idd );
      //---cargamos el combo
      cargar_cbo_rol();

      //--fecha de inicio
      const date = new Date();
      const fecha_now = date.toLocaleDateString('en-CA');
      state.form0.fecha_ini = fecha_now; 
       
      let idd: number = Number(route.params.idd) ;
      let ope = route.params.ope;

      //state.form0.idd = idd;
      //state.form0.ope = ope;
      
      if(ope=='n'){
        //this.limpiar();
      }else{
      
        editar(idd);
      }

    })
    //-----------------
    const cargar_cbo_rol = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_rol", param).then(res => {
          //if(res.data.length>0){ 
            state.roles = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    //--------------------------
    const handleCheckboxChange=(e: Event)=>{
      let ret = e.target as HTMLInputElement
      let sol = ret.checked? 1:0;
      state.form0.estado= sol;
    }
    //--------------------------
     

    //----------------------------------------------
    function editar(idd: number) {
   
      console.log("editar:" + idd);
      /*let param = { 
        idd: idd,
        ope:'f'
      }*/
      let param={
        id_tramite: idd
      }
      console.log(param);
      
      axios.post(process.env.VUE_APP_API_URL  + "tramitecrud_buscar", param).then(res => {
        console.log(res.data);
        let response = res.data;
          
          if(res.data!==""){ 
              //NotificationManager.success("Correcto");
              Object.assign(state.form0, res.data);
              state.form0.idd= idd;
              state.form0.ope= 'e';
              
              //----
              
              
          }else{
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT
            });
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    }
    //---------------------------------------------
    const aceptar_click = () => {
      console.log("aceptar_click");
      confirmRef.value?.show( 0, ".");
    }

    //--esta es la funcion que llama la confirmacion
    const handleConfirm=(id: number)=>{
        console.log("handleConfirm: " + id);

        save();
    }
    //-------------------------------------------
    //--- item.value, item.label
    const handleChangeDrop=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_tramite = item.value;
    }
    /*---------  ----------------*/
    const btn_search_click=(e: Event)=>{
      e.preventDefault();
      boxClienteRef.value?.show();
    }
    //-------------------------------------
    const btn_add_click=(e:Event)=>{
      e.preventDefault();
      clienteModalRef.value?.show();
    }
    function handleOkBox(index:number, item:any){
        console.log(item);
        //---buscamos el row al cual tenemos que cambiar
        console.log(item.razon_social);
        
        state.form0.id_cliente = item.id_cliente;
        state.form0.cliente = item.razon_social;
        /*let result = form00.miTabla.filter((row) => {
           row.id_cuenta === item.id_cuenta;
        });*/
    }
    function handleOkBoxCli(item:any){
      state.form0.id_cliente = item.id_cliente;
      state.form0.cliente = item.razon_social;
    }
    //==========================================================================
    const save =() =>{

        let param = state.form0;
        console.log(param);
        
        axios.post(process.env.VUE_APP_API_URL  + "tramitecrud_store", param)
        .then(res => {
            if(res.data.exito === 1){ 
                toast.success(res.data.men);
                console.log("res.data")
                console.log(res.data.data);
                //---ahora actualizamos el context API
                
            }else{
                toast.error(res.data.men);
            }

        }).catch(error => {
            alert("---- Existio un error ---");
            console.log(error);
            
        });
      
    }


    return{
      nuaRef,
      state,
      editar,
      close,
      aceptar_click,
      save,
      handleConfirm,
      confirmRef,
   
      IMAGE_API_URL,
      IMAGE_API_AFILES,
      cargar_cbo_rol,
      handleChangeDrop,
      handleCheckboxChange,
      boxClienteRef,
      btn_add_click,
      btn_search_click,
      handleOkBox,
      clienteModalRef,
      handleOkBoxCli,
    }
  }
}
</script>
<style >
 

</style>