<template>
  <div>
      {{ state }}
        <div class="content5">
          <h1> Bienvenido al sistema</h1>
          <p>Nidera</p>
          {{ state.selected_item }}
          <hr style="margin: 50px 0px; border: 1px solid rgb(227, 227, 227);">

          <div style="width: 300px;">
            <AutoComplete v-model="state.selected_item" 
                  :suggestions="state.items" 
                  @selected="onSelectedAuto" />
          </div>
        </div>
      
        <NuInputSpinner 
            :integerOnly="false"  
            value='10' 
            :max="1000" 
            v-model="state.test"/>

        <button v-on:click="mostrarAlert">Click</button>
        <button v-on:click="mostrarOKCancelBox">ClickOKCancel</button>
        <button v-on:click="mostrarTextDialog">ClickTextDialog</button>

        
        <OkCancelBox
          ref="okCancelBoxRef"
          ancho="380px"
          @confirm="handleConfirmBox"
          okLabel="ACEPTAR"
          cancelLabel="CANCELAR"
          >
          <!-- -->
          <template #header>
            <span class="jconfirm-icon-c"><i class="fa fa-question-circle"></i></span>
            &nbsp;&nbsp;
            <span>
              Titulo
            </span>
          </template>
          <template #body>
            I am injected content
          </template>
          <!-- -->
        </OkCancelBox>

        <TextDialog
          ref="textDialogRef"
          @okDialog="handleOkTextDialog"
          label="Codigo"
        >
        <template #header>Entrada</template>
        </TextDialog>

        <MessageBox
          ref="messageBoxRef"
        ></MessageBox>
        
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { ref, reactive, computed, onMounted } from "vue";
import { defineComponent, PropType } from "vue";
import axios from "@/shared/jwtInterceptor"; //axios interceptado

import AutoComplete from "@/components/nubox/AutoComplete.vue";
import NuInputSpinner from '@/components/nubox/NuInputSpinner.vue';
import OkCancelBox from '@/components/nubox/dialogs/OkCancelBox.vue';
import TextDialog from '@/components/nubox/dialogs/TextDialog.vue';
import MessageBox from '@/components/nubox/dialogs/MessageBox.vue';

import Swal from 'sweetalert2'



const initialForm = {
  idd: 0,
  ope: "n",
}

export default {
  name: 'Home',
  components: {
    AutoComplete,
    NuInputSpinner,
    OkCancelBox,
    TextDialog,
    MessageBox,
  },
  props: {},
  setup(props: any, context: any) {

    const okCancelBoxRef = ref<InstanceType<typeof OkCancelBox>>();
    const textDialogRef = ref<InstanceType<typeof TextDialog>>();
    const messageBoxRef = ref<InstanceType<typeof MessageBox>>();
            
    const state = reactive({
      modalInsertar: false,
      
      form0: initialForm,
      roles: [],
      cajas: [],
      selected_item:0,
      items: [
        { value: 0, label: "Sucre"},
        { value: 1, label: "Tarija"},
        { value: 2, label: "Potosi"},
        { value: 3, label: "Oruro"},
        { value: 4, label: "La Paz"},
        { value: 5, label: "Santa Cruz"},
        { value: 6, label: "Beni"},
        { value: 7, label: "Pando"},
        { value: 8, label: "El alto"},
      ],
      test:10,
    });

    const onSelectedAuto=()=>{

    }

    const mostrarAlert=()=>{
      Swal.fire({
            title: 'OPPS',
            text:   "wow",
            icon: 'warning',
          
        });
    }
    const mostrarOKCancelBox=()=>{
      //state.modalOkCancelBox= true;
      okCancelBoxRef.value?.show();
    }
    const handleConfirmBox=()=>{

    }
    //------------------------------
    const mostrarTextDialog=()=>{
      //state.modalOkCancelBox= true;
      //textDialogRef.value?.show();
      console.log("hola----");
      messageBoxRef.value?.show({
          title: 'Confirm to submit',
          message: 'Are you sure to do this.',
          classHeader: 'box_secondary',
          classIcon:'fa fa-times',
          buttons: [
            {
              label: 'Ok',
              btnClass: 'btn btn-success',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'Cancel',
              btnClass: 'btn btn-default',
              onClick: () => alert('Click No')
            }
          ]
        });
    }
    const handleOkTextDialog=(str:string)=>{
      console.log("handle home:" + str);
    }
    return {
      state,
      onSelectedAuto,
      mostrarAlert,
      mostrarOKCancelBox,
      okCancelBoxRef,
      handleConfirmBox,
      mostrarTextDialog,
      textDialogRef,
      handleOkTextDialog,
      messageBoxRef,
    };
  }
}
</script>

<style scoped lang="css">

</style>
