<!-- options:[ { value:0, label: "No Confirmado",color:"#008ffb"  },-->
<template>
  <div class='vnis' >
    <button
        @click='decreaseNumber'
        @mousedown='whileMouseDown(decreaseNumber)'
        @mouseup='clearTimer'
        :class='buttonClass'
        :disabled="disabled">-</button>
    <input
        type='number'
        v-bind:value='state.numericValue'
        @keypress='onKeyPress'
        @input='onInputValue'
        :class='inputClass'
        :min='min'
        :max='max'
        debounce='500'
        :disabled="disabled"
    />
    <button
        @click='increaseNumber'
        @mousedown='whileMouseDown(increaseNumber)'
        @mouseup='clearTimer'
        :class='buttonClass'
        :disabled="disabled">+</button>
  </div>
</template>

<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted,onBeforeMount,onBeforeUnmount } from 'vue';
import { watch,watchEffect } from 'vue' 
import moment from 'moment'
//import esLocale from "moment/locale/es";
import 'moment/locale/es'  // without this line it didn't work


export default defineComponent({
  components: {

  },
  props: {
  
    modelValue: { type: Number, default: 0 },
    min: { default: 0, type: Number },
    max: { default: 10, type: Number },
    step: { default: 1, type: Number },
    mouseDownSpeed: { default: 100,  type: Number },
    inputClass: {  default: 'vnis__input',  type: String  },
    buttonClass: { default: 'vnis__button',  type: String },
    integerOnly: { default: false,  type: Boolean },
    disabled: {  default: false,  type: Boolean }
  },
  setup(props, {emit}) {

    /*const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);*/
    //const inputRef = ref<HTMLInputElement | null>(null);//puntero al componente
    const state = reactive({
      numericValue:0,
      timer: 0,
    });
    /****************************************************************** */
    onMounted(() => {
      //----aqui la parte de created lo he colocado
      console.log("onMounted");

      /*if (props.modelValue) {
        state.computedValue = formatCurrency( props.modelValue.toString());
      }*/
      //--------------------colocamos un evento---------------
      //window.addEventListener('keyup', up);
    });

    // cada que haya cambios en serverOptions, se actualizan los datos
    watchEffect(()=>{
      
      console.log(props.modelValue);
      //if (props.modelValue) {
        state.numericValue = props.modelValue;
      //}
    });
    
    //------------------------------------------------
    /*const attributes = computed(()=>{
      const attr = { type: "text", id: props.id };
      return attr;
    });*/
    //------------------------------------------------

    //------------------------------------------------
    const clearTimer=()=> {
      if (state.timer) {
        clearInterval(state.timer);
        state.timer = 0;
      }
    }
    //----------------------------------------------
    //--en el fondo llamamos a increaseNumber
    const whileMouseDown=(callback:any)=> {
      if (state.timer === null) {
          state.timer = setInterval(() => {
            callback();
          }, props.mouseDownSpeed);
        
      }
    }
    //----------------------------------------------
    const increaseNumber=()=> {
      state.numericValue += props.step;
    }
    //----------------------------------------------
    const decreaseNumber=()=> {
      state.numericValue -= props.step;
    }
    //----------------------------------------------
    const onKeyPress=(evt:KeyboardEvent)=> {
      if (props.integerOnly === true) {
        isInteger(evt);
      } else {
        isNumber(evt);
      }
    }
    //----------------------------------------------
    const isInteger=(e: KeyboardEvent)=> {
      const target = e.target as HTMLInputElement;
      
      /*evt = evt ? evt : window.event;
      let key = evt.keyCode || evt.which;
      key = String.fromCharCode(key);*/
      let key =  e.key;
      const regex = /[0-9]/;

      if (!regex.test(key)) {
        //e.returnValue = false;
        target.value = '';  //lo quitamos
        //if (e.preventDefault) evt.preventDefault();

      }
    }

    //----------------------------------------------
    const isNumber=(e: KeyboardEvent)=> {
      const target = e.target as HTMLInputElement;
      /*var code = e.keyCode || e.charCode
      ;*/
      //let charCode:number = String.fromCharCode(e.key) ;
      let charCode:number = e.key.charCodeAt(0); //sacamos su cod ascii

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46  //.
      ) {
        e.preventDefault();
      } else {
        return true;
      }
    }
    
    //----------------------------------------------  
    const onInputValue=(e:Event)=> {
      const target = e.target as HTMLInputElement;

      state.numericValue = target.value
        ? parseInt( target.value)
        : props.min;
    }

    //-----------------------------
    watch(() =>state.numericValue, (val, oldVal) => {
      if (val <= props.min) {
        //state.numericValue = parseInt(props.min);
        state.numericValue = props.min;
      }

      if (val >= props.max) {
        //state.numericValue = parseInt(props.max);
        state.numericValue = props.max;
      }

      if (val <= props.max && val >= props.min) {

        //this.$emit('input', val, oldVal);
        //emit('input',val,oldVal);
        //actualiza el v-model="state.selected_item"  que esta afuera
        emit('update:modelValue', val);
      }
    });
    
    //------------------------------------
    return{
      onMounted,
      state,
      //inputRef,
      clearTimer,
      whileMouseDown,
      onInputValue,
      isNumber,
      isInteger,
      onKeyPress,
      decreaseNumber,
      increaseNumber,
     
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss'>
.vnis {
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  &__input {
    -webkit-appearance: none;
    border: 1px solid #ebebeb;
    float: left;
    font-size: 16px;
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    width: calc(100% - 80px);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &__button {
    -webkit-appearance: none;
    transition: background 0.5s ease;
    background: #387e90;
    border: 0;
    color: #fff;
    cursor: pointer;
    float: left;
    font-size: 20px;
    height: 40px;
    margin: 0;
    width: 40px;

    &:hover {
      background: lighten(#387e90, 10%);
    }

    &:focus {
      outline: 1px dashed lighten(#387e90, 20%);
      outline-offset: -5px;
    }
  }
}
</style>
