import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", _mergeProps({
    value: _ctx.state.computedValue
  }, _ctx.attributes, {
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
    onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.up && _ctx.up(...args))),
    ref: "inputRef",
    style: _ctx.style,
    placeholder: _ctx.placeholder,
    class: _ctx.clase
  }), null, 16, _hoisted_1))
}