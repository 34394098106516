<template>
  <div>
    <Modal :showModal="isModalVisible" @closeModal="closeModal" :flagFooter="false">
      <template v-slot:title>Abono cliente</template>
      <!-- body-->
      
      <form  method='POST'>

        <div class=" p-2 form_degrade_azul">
        <div>
          <b>Documento:</b> <span id="t_doc" class="text-danger">{{ state.form0.ndoc }}</span>
        </div>
        <b>Saldo:</b> <span id="t_rs2" style="font-size: 14pt">{{ state.form0.saldo }}</span>

        <div class="form_degrade_celeste" style="margin-top:8px; margin-botoom:8px">

          <table class="tabla_basic">
            <tbody><tr>
              <td><b>Fecha de pago:</b></td>
              <td colspan="2">
                <!-- 2024-10-17 -->
                <input 
                    name="fecha_pago" 
                    type="date" 
                    v-model="state.form0.fecha_pago" >
              </td>
            </tr>

            <tr>
              <td><b>Nombre del pagador:</b></td>
              <td colspan="2">
                <input name="nombre_pagador" 
                      type="text" 
                      v-model="state.form0.nombre_pagador" >
              </td>
            </tr>

            <tr>
              <td><b>Abona con:</b></td>
              <td colspan="2">
                <input name="abonacon" type="number" min="1" id="abonacon" 
                    v-model="state.form0.abona_con" style="width:100px;">
              </td>
              <td></td>
            </tr>

          </tbody></table>

        </div>

        <div class="modal-footer">

          <button type="button" class="btn btn btn-primary" @click="okModal">Aceptar</button>
          <button type="button" class="btn btn-default" @click="closeModal">Cancelar</button>

        </div>

      </div>
      </form>
      <!-- -->
      
      <!--<template v-slot:footer>
        <button  class="btn btn-primary" @click="okModal">Guardar</button>
        <button  class="btn btn-default" @click="closeModal">Cancel</button>
      </template>-->

      
    </Modal>
  </div>
</template>

<script lang="ts"  >
import { useStore } from 'vuex';
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import moment from 'moment';
import { IUser } from '@/models/IUser';
import cUtilFechaM from '@/helpers/cUtilFechaM'

import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 


const initialForm={
  idd:0,
  ope:'n',
  id_cxc:0,
  id_cliente:0,
  razon_social:'',
  ndoc:'',
  saldo:0,
  fecha_pago:'',
  nombre_pagador:'',
  abona_con:0,
}

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    title: {  type: String,  required: false, },
  },
  setup(_,context:any) {
    
    const isModalVisible = ref(false);
    const store = useStore(); //para vuex
    //const user = ref<IUser>();
    const user = ref({} as IUser);
    

    const state = reactive({
      modalInsertar:false,
      form0: Object.assign({},initialForm),
      m___s:[],
   
      //selected_estado:0,
      e___s:[ { value:0, label: "No Confirmado",color:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#ce9320" },
              ],
    });


    /*const state = reactive({
      modalInsertar:false,
     
    });*/

    onMounted(() => {
      user.value = store.getters['auth/getUser']; 
      let id_u = user.value.id_u;
      let username = user.value.username;
      //let today = moment();
      //let today = moment(new Date()).format('MM/DD/yyyy hh:mm A [GMT]ZZ');
      let today = moment(new Date()).format('YYYY-MM-DD');
      state.form0.fecha_pago = today;
    });

    function limpiar(){
      state.form0 = Object.assign({},initialForm);
    }

    const okModal=()=>{
      isModalVisible.value = false;

      formSave();
      //---esto esta dentro del form
      //context.emit("okModal", state.form0.id_cliente);
    }

    const closeModal = () => {
      console.log("evento close");
      isModalVisible.value = false;
      //context.emit("closeModal");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };

    //---------------------------------------------------------------------
    function show(id_cxc:number, ndoc: string, saldo:number) {
      //--evento hacia afuera
      console.log("show");
      //console.log(item);

      isModalVisible.value = true;
      //state.form0 = item;
      state.form0.idd = id_cxc;
      state.form0.id_cxc = id_cxc;
      state.form0.ndoc = ndoc;
      state.form0.saldo = saldo;
      state.form0.fecha_pago= cUtilFechaM.dateNow();
      state.form0.nombre_pagador="";
      state.form0.abona_con=0;
      //----------------cambiamos la fecha a actual

    }
    //-------------------------------------------
    const formSave=()=>{
        let id_u = user.value?.id_u;
        //context.emit("okModal",indexTable.value, item);
        //let param = state.form0;
        const param={id_cxc: state.form0.id_cxc,
          fecha_emi:state.form0.fecha_pago,
          nombre_paga: state.form0.nombre_pagador,
          abonacon:state.form0.abona_con,
          id_u: id_u,
        }
        console.log(param);

        axios.post( process.env.VUE_APP_API_URL +"cxc_estadocuenta_save_abono", param).then(res => {
            let response = res.data;
            console.log("clientecrud_store");
            console.log(res.data);

            if(response.exito===1){     
              toast.success( response.men);
              //---llamamos a la funcion externa
              closeModal();
              //this.props.okModal(); //callback

              //isModalVisible.value = false;
              //context.emit("okModal",state.form0);
              context.emit("okModal",state.form0.id_cliente);
            }else{
                
              toast.error( response.men);
            }

        }).catch(error => {
            //if(error.response.status && error.response.status===400)
            alert("Bad Request - ");
            console.log(error);
            
        });
    }
    //-------------------------------------------
    //-------------------------------------------
    
    return {
      isModalVisible,
      state,
      limpiar,
      okModal,
      closeModal,
      show,
      formSave,
      
      
    };
  },
})
</script>
<style >
/*para dialog ventas, compras*/
.form_degrade_azul {
  -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #a5c3e7;
    background-image: -moz-linear-gradient(center top, #a5c3e7, #82a6e2);
    border-color: #a3c2f8 #a3c2f8 #a3c2f8 #a3c2f8;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 3px;
    color: #060606;
    padding: 8px 0;
    /* text-align: center; */
    color: #000;
}
.form_degrade_celeste {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fefefe;
    background-image: -moz-linear-gradient(center top, #fefefe, #d3ebfa);
    border-color: #2a436c #2a436c #2a436c #2a436c;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: #060606;
    padding: 8px 0;
    /* text-align: center; */
    color: #000;
}
</style>