<!-- options:[ { value:0, label: "No Confirmado",color:"#008ffb"  },-->
<template>
  <input 
      :value="state.computedValue" 
      v-bind="attributes"       
      @input='change'
      @keyup="up"
      ref="inputRef" 
      :style="style"
      :placeholder="placeholder"
      :class="clase"/>
</template>

<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted,onBeforeMount,onBeforeUnmount } from 'vue';
import { watch,watchEffect } from 'vue' 
import moment from 'moment'
//import esLocale from "moment/locale/es";
import 'moment/locale/es'  // without this line it didn't work


export default defineComponent({
  components: {

  },
  props: {
    modelValue: { type: Number,  },
    placeholder: { type: String,   },
    name: { type: String,   },
    id: { type: String,   default: "input-id", },
    style: { type: Object,   default: {}, },
    clase: { type: String,   default: '', },
  },
  setup(props, {emit}) {

    /*const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);*/
    const inputRef = ref<HTMLInputElement | null>(null);//puntero al componente
    const state = reactive({
      computedValue: '',
    });
    /****************************************************************** */
    onMounted(() => {
      //----aqui la parte de created lo he colocado
      console.log("onMounted");

      /*if (props.modelValue) {
        state.computedValue = formatCurrency( props.modelValue.toString());
      }*/
      //--------------------colocamos un evento---------------
      //window.addEventListener('keyup', up);
    });

    // cada que haya cambios en serverOptions, se actualizan los datos
    watchEffect(()=>{
      
      console.log(props.modelValue);
      //if (props.modelValue) {
        state.computedValue = formatCurrency( props.modelValue!.toString());
      //}
    });
    
    //------------------------------------------------
    const attributes = computed(()=>{
      const attr = { type: "text", id: props.id };
      /*props.placeholder && (attr["placeholder"] = props.placeholder);
      props.name && (attr["name"] = props.name);*/
      return attr;
    });
    //----------------------------------------------
    const up=(e: Event)=>{
      const target = e.target as HTMLInputElement;
      
      console.log("up");
      console.log(target.value);

      //state.computedValue = formatCurrency(target.value);
      //--convertimos cadena a numero
      const numericValue = convertToFloat(target.value);
      //actualiza el v-model="state.selected_item"  que esta afuera
      emit('update:modelValue', numericValue);
    }
    const change=(e:Event)=> {
      const target = e.target as HTMLInputElement;
      //console.log(target);
    }
    //------------------------------------------------------------------------
    function formatCurrency(value: string) {
      value = parseCurrency(value);
      let newValue;
      let [whole, fraction] = value.split(".");
      // converting whole to number as toLocaleString works with numbers only
      let num:number = parseFloat(whole);
      //if (whole !== "") whole = +whole;
      if (whole !== "") num = +num;
      // if user types a . as the first character
      if (whole === "" && fraction === "") {
        //whole = 0;
        num = 0;
      }
      //.......................
      if (fraction && fraction.length > 2) {
        // fraction can atmost have length 2
        fraction = fraction.substring(0, 2);
      }
      //.......................
      if (fraction) {
        //newValue = whole.toLocaleString("en-US") + "." + fraction;
        newValue = num.toLocaleString("en-US") + "." + fraction;
      } else if (fraction === "") {
        // fraction will be empty string when user enters something like 97.
        //newValue = whole.toLocaleString("en-US") + ".";
        newValue = num.toLocaleString("en-US") + ".";
      } else {
        //newValue = whole.toLocaleString("en-US");
        newValue = num.toLocaleString("en-US");
      }
      if (newValue === "") {
        emit("input", "");
        return "";
      }
      
      emit("input", parseCurrency(newValue));
      //return "$" + newValue;
      return newValue;
    }
    //------------------------------------
    function parseCurrency(value: string) {
      
      value = value.toString();
      // removing everything except \d and \. (numbers and decimal)
      let parsed = value.replace(/[^\d|\.]/g, "");
      return parsed;
    }
    //------------------------------------
    function convertToFloat(val: string) {
        let sol=0;
        
        console.log('ctf', val);
        if (val != '') {
            //--si tiene comas
            console.log("indexOf:" + val.indexOf(','));

            if (val.indexOf(',') !== -1){
                val = val.replace(",", "");
            }

            //------------convertimos a numero
            let val1 = parseFloat(val);
            sol= val1;
        }
        return sol;
    }
    //------------------------------------
    return{
      onMounted,
      state,
      inputRef,
      attributes,
      formatCurrency,
      parseCurrency,
      up,
      change,
      convertToFloat,
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  label.switch {
        input[type="checkbox"] {
            display: none;
            &:checked {
                + span {
                    &:before {
                        background-color: rgba(#007FEB, 0.5);
                    }
                    &:after {
                        background-color: #007FEB;
                        transform: translate(80%, -50%);
                    }
                }
            }
            + span {
                position: relative;
                display: inline-block;
                cursor: pointer;
                font-weight: 500;
                text-align: left;
                margin: 0px;
                padding: 0px 44px;
                &:before,
                &:after {
                    content: '';
                    cursor: pointer;
                    position: absolute;
                    margin: 0;
                    outline: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    transition: all 200ms ease-out;
                }
                &:before {
                    left: 1px;
                    width: 34px;
                    height: 14px;
                    background-color: rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                }
                &:after {
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background-color: rgb(166, 164, 164);
                    border-radius: 50%;
                    /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .14), 0 2px 2px 0 rgba(0, 0, 0, .098), 0 1px 5px 0 rgba(0, 0, 0, .084);*/
                }
            }
            &:checked + span &:after {
                transform: translate(80%, -50%);
            }
        }
    }
</style>
