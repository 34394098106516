import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vnis" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["value", "min", "max", "disabled"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decreaseNumber && _ctx.decreaseNumber(...args))),
      onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.whileMouseDown(_ctx.decreaseNumber))),
      onMouseup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearTimer && _ctx.clearTimer(...args))),
      class: _normalizeClass(_ctx.buttonClass),
      disabled: _ctx.disabled
    }, "-", 42, _hoisted_2),
    _createElementVNode("input", {
      type: "number",
      value: _ctx.state.numericValue,
      onKeypress: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onKeyPress && _ctx.onKeyPress(...args))),
      onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onInputValue && _ctx.onInputValue(...args))),
      class: _normalizeClass(_ctx.inputClass),
      min: _ctx.min,
      max: _ctx.max,
      debounce: "500",
      disabled: _ctx.disabled
    }, null, 42, _hoisted_3),
    _createElementVNode("button", {
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.increaseNumber && _ctx.increaseNumber(...args))),
      onMousedown: _cache[6] || (_cache[6] = ($event: any) => (_ctx.whileMouseDown(_ctx.increaseNumber))),
      onMouseup: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.clearTimer && _ctx.clearTimer(...args))),
      class: _normalizeClass(_ctx.buttonClass),
      disabled: _ctx.disabled
    }, "+", 42, _hoisted_4)
  ]))
}