<template>
  <div class="container">
    <input type="hidden" id="id_v" value="{{$id_v}}" />

    

    <div style="width:100%" class="text-center">

      <span style="color:#330099; "> </span>
      <h3>Estado de cuenta</h3>
   
      
      

    </div>

    <div>
      <NuLoading :loading="isloading" text="Cargando..." />
    </div>

    <div class="row justify-content-center">
      <div class="col-md-12">
        <!-- toolbar-->
        <div style="width: 100%;  float: left;   background-color: #ccc6c1;  background-image: -moz-linear-gradient(center bottom, #c7defe,   #e7f1fe  );
            border: solid 1px #c0c5ca;
            display: block;
          ">
          <div class="buttons">
            <img class="separador" src="~@/assets/img/m_sepini.png" alt="" />

            <a href="#" class="regular" @click="btn_search_click">
              <img src="~@/assets/img/m_find_people.png" alt="" />
              Cliente
            </a>

            <a href="#" class="regular" @click="buscar_click">
              <img src="~@/assets/img/m_buscar.png" alt="" />
              Buscar
            </a>

            <img class="separador" src="{{asset('img/m_sepmed.png')}}" alt="" />

            <a href="" class="regular" @click="refresh_click">
              <img src="~@/assets/img/refresh.png" alt="" />
              Recargar
            </a>

            <a href="" class="regular" @click="reimprime_click">
              <img src="~@/assets/img/m_printer.png" alt="" />
              Reimprimir
            </a>

            <img class="separador" src="~@/assets/img/m_sepmed.png" alt="" />

            <a href="#" class="regular" @click="agregar_cxc_click">
              <img src="~@/assets/img/m_add.png" alt="" />
              Agregar CXC
            </a>
          </div>

        </div>
        <!-- fin toolbar-->

        <div class="">
          <table style="background-color: #fff; width: 100%" class="tabla_blanca" cellpadding="5px">
            <tbody>
              <tr>
              <td width="90">
                <span class="tooltip-content" style="color:#6666cc; "><b>Saldo Actual</b> </span>
              </td>
              <td width="64" align="center">
                <label style="">{{ state.form0.saldo_actual }}</label>
              </td>
              <td width="90"><span class="tooltip-content" style=" color:#6666cc; "><b>Limite de credito</b> </span>
              </td>
              <td width="78">
                  <label>{{ state.form0.limite }}</label>
              </td>
            </tr>
              <tr>
                <td class="text-right" width="107">
                  <b>Cod. cli:</b>
                </td>
                <td class="alignLeft" width="136">
                  <label>{{ state.form0.id_cliente }}</label>
                </td>
                <td class="text-right" width="150"><B></B></td>
                <td width="105">
                  <label></label>
                </td>
              </tr>
              <tr>
                <td class="text-right"><b>Cliente:</b></td>
                <td class="alignLeft">
                  <label>{{ state.form0.razon_social }}</label>
                </td>
                <td class="text-right"><b></b></td>
                <td>
                  
                </td>
              </tr>
              

              
              <tr>
                <td colspan="4"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-responsive" style="float: left;width: 100%;">
          <table id="tabla01" class="tabla_plomo" style="background-color: #fff; width: 100%" cellpadding="5px"
            align="left">
            <thead>
              <tr>
                <th>Tipo Doc.</th>
                <th>Documento</th>
                <th>Fecha</th>
                <th>Total</th>
                <th>Estado</th>
                <th>Saldo</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item,index) in state.items" :key="index">
                <td>{{ item.id_cxc }} </td>
                <td>{{ item.ndoc }} </td>
                <td>{{ item.fecha_emi }} </td>
                <td>{{ item.cargo }} </td>
                <td>
                  <p v-if="item.estadocxc === 'P'"><span class="badge badge-pill badge-warning">Pendiente</span></p>
                  <p v-else-if="item.estadocxc === 'C'"><span class="badge badge-pill badge-success">Cancelado</span></p>
                </td>
                <td>{{ item.saldo }} </td>
                <td>
                  <button @click="abonar_clic( item.id_cxc, item.ndoc, item.saldo ) " class="btn btn-primary">Abonar</button>
                  <button @click="cargar_clic( item) " class="btn btn-warning">Cargar</button>
                  <button @click="kardex_clic( item.id_cxc )" class="btn btn-default">Kardex</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- ---------------->

  </div>
  
  <OkCancelBox ref="okCancelBoxRef" ancho="380px" @confirm="handleConfirmBox" okLabel="ACEPTAR" cancelLabel="CANCELAR">
    <!-- -->
    <template #header>
      <span class="jconfirm-icon-c"><i class="fa fa-question-circle"></i></span>
      &nbsp;&nbsp;
      <span>
        Borrar
      </span>
    </template>
    <template #body>
      ¿Esta seguro de eliminar este registro?
    </template>
    <!-- -->
  </OkCancelBox>

  <BoxBuscarCliente 
    ref="boxClienteRef"
    @okModal="handleOkBox" 
  />

  <AbonoModal
    ref="abonoModalRef"
    title="Abono cliente"
    @okModal="abonoOkBox" 
  />
  
  
  <CargoModal
    ref="cargoModalRef"
    title="Cargo cliente"
    @okModal="cargoOkBox" 
  />

  <CxcNuevoModal
    ref="cxcNuevoModalRef"
    title="Cargo cliente"
    @okModal="cxcNuevoModalOkBox" 
  />

</template>

<script lang="ts">
// @ is an alias to /src
import { ref, reactive, computed, onMounted } from "vue";
import { defineComponent, PropType } from "vue";
import axios from "@/shared/jwtInterceptor"; //axios interceptado

import NuLoading from '@/components/nubox/NuLoading.vue'; 
import EstadoBadge from '@/components/EstadoBadge.vue'; 
import OkCancelBox from '@/components/nubox/dialogs/OkCancelBox.vue';
import BoxBuscarCliente from '@/pages/_box/BoxBuscarCliente.vue'; //~@ 
import { toast } from 'vue3-toastify';
import Swal from "sweetalert2";
import AbonoModal from "./AbonoModal.vue";
import CargoModal from "./CargoModal.vue";
import CxcNuevoModal from "./CxcNuevoModal.vue";

const initialForm = {
  idd: 0,
  ope: "n",
  id_cliente: 1,
  razon_social: 'GENERAL',
        
  saldo_actual:0,
  limite:0,
  estado:0,
  nota:'',
  condicion:'',
  id_cli:'',
  nom_cli: '',
  idtipod:'',
  id_v:'',
};
//--- lo exportamos para que desde afuera lo puedan declarar
interface ItemRow {
  id_cxc:number,
  id_tipod: number,
  ndoc: string,
  cargo: number,
  estadocxc: string,
  fecha_emi: string,
  saldo: number,
}
//==========================================================================
export default {
  name: "Home",
  components: {
    NuLoading,
    EstadoBadge,
    OkCancelBox,
    BoxBuscarCliente,
    AbonoModal,
    CargoModal,
    CxcNuevoModal,
  },
  props: {},
  setup(props: any, context: any) {

    const isloading = ref(false);
    const okCancelBoxRef = ref<InstanceType<typeof OkCancelBox>>();
    const boxClienteRef = ref<InstanceType<typeof BoxBuscarCliente>>(); //puntero al componente

    const abonoModalRef = ref<InstanceType<typeof AbonoModal>>(); //puntero al componente
    const cargoModalRef = ref<InstanceType<typeof CargoModal>>(); //puntero al componente
    const cxcNuevoModalRef = ref<InstanceType<typeof CxcNuevoModal>>(); //puntero al componente
      

    const state = reactive({
      modalInsertar: false,
      form0: initialForm,
      roles: [],
      cajas: [],
      selected_item: 0,
      estados:[ { value:0, label: "Pendiente",clase:"bg-warning text-dark"  },
                { value:1, label: "Activo", clase:"bg-success" },
                { value:2, label: "Anulado", clase:"bg-secondary" },
              ],
      
      test: 10,
      items: [] as ItemRow[],
    });

    //----------------------------------------------
    function buscar_click(){
      let x = prompt("Codigo de cliente");
      if(x!=null){
        console.log(x);
        buscarData( parseInt(x) );
      }
    }
    /*---------  ----------------*/
    const btn_search_click = (e: Event) => {
      console.log("btn_search_click");
      e.preventDefault();
      boxClienteRef.value?.show();
    };
    //--------------------------
    function handleOkBox(index: number, item: any) {
      console.log(item);
      //---buscamos el row al cual tenemos que cambiar
      console.log(item.razon_social);
      buscarData( item.id_cliente );
      //state.form0.id_cliente = item.id_cliente;
      //state.form0.cliente = item.razon_social;

      /*let result = form00.miTabla.filter((row) => {
       row.id_cuenta === item.id_cuenta;
      });*/
    }
    //----------------------------------------------
    function buscarData(id:number){
        console.log("buscarData");
        isloading.value =true; //muestra cargando
        
        const param={id: id}
        console.log(param);
        axios.post( process.env.VUE_APP_API_URL + "cxc_estadocuenta_getcli", param)
        .then(res=>{
            isloading.value =false; //quita cargando
            console.log(res.data);
            if(res.data.exito==1){
                Object.assign(state.form0, res.data);
                state.form0.idd= id;
                state.form0.ope= 'e';
                //---------asignamos el array
                state.items = res.data.data;
            }else{
                toast(res.data.men, { autoClose: 1000, }); // ToastOptions
            }
            
        })
        .catch(error => {
            isloading.value = false;//quita cargando
        //	this.errorMessage = error.message;
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }
    //-----------------------------------------------
    const reimprime_click =()=>{
      let fileURL = process.env.VUE_APP_API_URL + "ventascrud_reciboPDF/"+ state.form0.id_v;
      let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
      window.open(fileURL,'',windowFeatures);
    }
    //-----------------------------------------------
    const anular_click=()=>{
      okCancelBoxRef.value?.show();
    }
    const handleConfirmBox=()=>{
      anularStore(parseInt(state.form0.id_v));
    }
    //----------------------------------------------
    function anularStore(id:number){
        console.log("anularStore");
        isloading.value =true; //muestra cargando
        
        const param={id: id}
        console.log(param);
        axios.post( process.env.VUE_APP_API_URL + "venta_anular_store", param)
        .then(res=>{
            isloading.value =false; //quita cargando
            console.log(res.data.data);
            if(res.data.exito==1){
              Swal.fire({
                title: "Mensaje",
                text: "Procesado con éxito",
                icon: "warning",
              });
            }else{
                toast(res.data.men, { autoClose: 1000, }); // ToastOptions
            }
            
        })
        .catch(error => {
            isloading.value = false;//quita cargando
        //	this.errorMessage = error.message;
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }
    //-----------------------------------------------
    const agregar_cxc_click=()=>{
      console.log("showModal");
      //item.razon_social = state.form0.razon_social;
      cxcNuevoModalRef.value?.show(state.form0.id_cliente, state.form0.razon_social);
    }
    //----------------------------------------------
    function abonar_clic(id_cxc: number,ndoc: string ,saldo: number){    
      console.log("showModal");
      abonoModalRef.value?.show(id_cxc, ndoc, saldo);
      
    }
    //----------------------------------------------
    function cargar_clic(item: any){    
      console.log("showModal");
      item.razon_social = state.form0.razon_social;
      cargoModalRef.value?.show(item);
      
    }
    //----------------------
    function kardex_clic(id_cxc: number){    
      console.log("kardex_clic");
           
      let fileURL = process.env.VUE_APP_API_URL + "cxc_estadocuenta_print_kardexPDF/"+ id_cxc.toString();
      let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
      window.open(fileURL,'',windowFeatures);
      
    }    
    //-----------------------------------------------------------------------------
    function abonoOkBox(id_cliente: number){
      console.log("abono ok");
      //tenemos que hacer un refresh
      //buscarData( id_cliente );
      buscarData( state.form0.id_cliente );

    }
    function cargoOkBox(id_cxc: number){
      console.log("cargo ok");
      //-- est un refresh
      buscarData( state.form0.id_cliente );
    }
    //-----------------------------------------------
    function cxcNuevoModalOkBox(item: any){    
      console.log("cxcNuevoModalOkBox");
      //-- est un refresh
      buscarData( state.form0.id_cliente );
    }
    //----------------------------------------------
    
    //-----------------------------------------------
    const refresh_click =() =>{}
    const mostrarAlert = () => {
      Swal.fire({
        title: "OPPS",
        text: "wow",
        icon: "warning",
      });
    };

    return {
      isloading,
      state,
      buscarData,
      mostrarAlert,
      buscar_click,
      reimprime_click,
      anular_click,
      anularStore,
      okCancelBoxRef,
      handleConfirmBox,
      refresh_click,
      boxClienteRef,
      handleOkBox,
      btn_search_click,
      abonar_clic,
      cargar_clic,
      kardex_clic,
      abonoModalRef,
      cargoModalRef,
      abonoOkBox,
      cargoOkBox,
      agregar_cxc_click,
      cxcNuevoModalOkBox,
      cxcNuevoModalRef,
    };
  },
};
</script>
