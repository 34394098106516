import moment from 'moment';
/* 
//Examples of ISO format: YYYY-MM-DD
  const fechax = new Date(value);
//compensar la diferencia por la zona horaria
  fechax.setMinutes(fechax.getMinutes() + fechax.getTimezoneOffset())
*/

class cUtilFechaM {
  //--------calculamos la edad segun la fecha de nacimiento
  static dateNow(): string{
    return moment(new Date()).format('YYYY-MM-DD');
  }


}

export default cUtilFechaM;