import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matches, (itemRow, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        class: _normalizeClass(`badge rounded-pill ${itemRow.clase}`)
      }, _toDisplayString(itemRow.label), 3))
    }), 128))
  ]))
}