import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch" }
const _hoisted_2 = ["checked", "name", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(_ctx.classes),
      type: "checkbox",
      checked: _ctx.checked,
      name: _ctx.name,
      disabled: _ctx.disabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.value) = $event))
    }, null, 10, _hoisted_2), [
      [_vModelCheckbox, _ctx.state.value]
    ]),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}