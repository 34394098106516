<template>
    <div>
        <!-- -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/main">
                        <i class="fas fa-home"></i>Inicio
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/inmuebles">Tabla inmuebles</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">usuario</li>
            </ol>
        </nav>
        <!-- -->
        {{ JSON.stringify(state.form0) }}
        {{ JSON.stringify(state.form0.id_moneda)  }}
        <!-- -->
        <div>
            <legend style="text-align:center;"><strong>Formulario de Producto</strong></legend>
            <div class="form-group" id="picture" style="text-align: center;">

            </div>
        </div>
        <br>
        <div class="row ">
          <div class="col-md-12" style="display: flex;align-items: center;justify-content: center;">
            <div class="mb-3 col-md-12" style="max-width: auto;">

              <form id="form0" method="post">
                <!-- aqui el panel principal-->
                <div class="box box-default mx-auto" style="width: 800px; border: 1px solid #c5ab5c;">

                  <div class="box-header with-border" style="background-color: burlywood;">
                    <h3 class="box-title">Productos y servicios</h3>

                  </div>
                  <!-- /.box-header -->
                  <div class="box-body" style="background-color: #fff4e6;">
                    <div class="row2">
                      <!-------------------------------->
                      <table class="tabla_estilo2" style="text-align: left;">
                        <thead>
                        </thead>

                        <tbody>
                          <tr>
                            <td class="celda-cafe1" colspan="7">
                              General 
                            </td>
                          </tr>
                          <!-- -->
                          <tr>
                            <td class="text-bold text-right" width="200"> 
                              <span class="class_red">*</span>Marca/Linea
                            </td>
                            <td colspan="3" width="28%">                              
                              <NuDropDown
                                  name="id_marca" 
                                  class="form-select1"
                                  :options = "state.marcas"
                                  v-model= "state.form0.id_marca"
                                  @onChange ="handleChangeDropMarca"
                              />
                            </td>
                            
                            <td>
                              <button id="btn_marca_add" name="btn_marca_add" class="btn" style="padding: 4px" onclick="show_marca(); return false;">
                                <img src="~@/assets/img/create.png">
                              </button></td>
                              
                            <td class="text-bold  text-center" width="">Código</td>
                            <td rowspan="4" class="text-center" width="28%">
                              <div class="card-body">
                                    <ImageUploadSingle  
                                          @okImage= "okImage1"
                                          @cancelImage= "cancelImage1"
                                          :image_API_URL="IMAGE_API_URL"
                                          :image_PATH_FILES = "IMAGE_API_AFILES"
                                          :fileName="state.form0.foto_path1"
                                        />
                                  
                              </div>
                              
                            </td>
                          </tr>
                          <!----->
                          <tr>
                            <td class="text-bold text-right"> <span class="class_red">*</span>Categoria:</td>
                            <td colspan="3">
                              
                              <NuDropDown
                                  name="id_pcat" 
                                  class="form-select1"
                                  :options = "state.producto_categoria_arr"
                                  v-model= "state.form0.id_pcat"
                                  @onChange ="handleChangeDropProdCat"
                              />
                            </td>
                            <td>
                              <button class="btn" style="padding: 4px" onclick="show_cate(); return false;">
                                <img src="~@/assets/img/create.png">
                              </button>
                            </td>
                            <td class="text-center">
                              <h4 id="id_prod" disabled="">306</h4>
                            </td>
                          </tr>
                          <!----->

                          <tr>
                            <td class="text-bold text-right"> <span class="class_red">*</span>Nombre Producto</td>
                            <td colspan="4">
                              <!--<input name="nombre_p" type="text" id="nombre_p" 
                                class="w-100" data-valtipo="LN" data-valreq="true"
                                value="" 
                              />-->
                              <textarea 
                                  name="nombre_p" 
                                  rows="2" cols="50" 
                                  style="width: 100%;" 
                                  data-valtipo="LN" data-valreq="true"
                                  v-model="state.form0.nombre_p">
                              </textarea>
                            </td>
                            <td>
                              <NuDropDown
                                  name="unidad" 
                                  class="form-select1"
                                  :options = "state.unidad_arr"
                                  v-model= "state.form0.unidad"
                                  @onChange ="handleChangeDropProdCat"
                              />                  
                              </td>
                          </tr>
                          <!----->

                          

                          <tr>

                            <td class="text-bold text-right"> <span class="class_red">*</span>Detalle:</td>
                            <td colspan="4">
                              <textarea 
                                  name="descrip" 
                                  rows="5" cols="50" 
                                  style="width: 100%;" 
                                  data-valtipo="LN" 
                                  data-valreq="true"
                                  v-model="state.form0.descrip">
                              </textarea>
                              
                            </td>
                            
                            <td></td>
                            

                          </tr>

                          <tr>

                            <td class=" text-right"><span class="class_red">*</span>Clase de Producto:</td>
                            <td colspan="3">
                              <NuDropDown
                                  name="id_pclase" 
                                  class="form-select1"
                                  :options = "state.producto_clase_arr"
                                  v-model= "state.form0.id_pclase"
                                  @onChange ="handleChangeDropPClase"
                              />
                            </td>

                          </tr>
                          <tr>

                            <td class=" text-right"><span class="class_red">*</span> Utiliza lotes:</td>
                            <td colspan="2" class="text-left">
                              <div class="form-group ">
                                <NuCheckBox 
                                    label="" 
                                    value="foo" 
                                    v-model="state.form0.usa_lotes_sn"
                                    custom="false" />
                                
                              </div>
                            </td>

                          </tr>

                          <tr>
                            <td class=" text-right">Cód. de barra:</td>
                            <td colspan="3">
                              <input 
                                name="cod_barra" 
                                type="text"
                                class="" 
                                v-model="state.form0.cod_barra" 
                                style="width: 100%"/>
                              </td>
                          </tr>


                          <tr>
                            <td class=" text-right"><span class="class_red">*</span>Minimo:</td>
                            <td> 
                              <input 
                                type="number" 
                                name="stock_min" 
                                v-model="state.form0.stock_min"
                                onfocus="this.select()" 
                                min="1" 
                                data-valtipo="ND" 
                                data-valreq="true" 
                                style="width: 80px">
                            </td>
                          </tr>

                          <tr>
                            <td class=" text-right"><span class="class_red">*</span>Máximo:</td>
                            <td> 
                              <input 
                                type="number" 
                                name="stock_min" 
                                v-model="state.form0.stock_max" 
                                onfocus="this.select()" 
                                min="1" 
                                data-valtipo="ND" 
                                data-valreq="true" 
                                style="width: 80px">
                            </td>
                          </tr>

                          <tr>
                            <td class=" text-right"><span class="class_red">*</span>Proveedor:</td>
                            <td colspan="3">
                              <NuDropDown
                                  name="id_prov" 
                                  class="form-select1"
                                  :options = "state.proveedor_arr"
                                  v-model= "state.form0.id_prov"
                                  @onChange ="handleChangeDropProveedor"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td class="text-right">Ubicación:</td>
                            <td colspan="3">
                              <input 
                                  name="ubicacion" 
                                  type="text" 
                                  v-model="state.form0.ubicacion" 
                                  style="width: 100%">
                            </td>
                          </tr>
                          <!-- ------------------>
                          <tr>
                            </tr></tbody></table><table class="tabla_basic" style="width: 100%;text-align: left;">
                              <tbody><tr>
                                <td class="celda-cafe1" colspan="4">
                                  Precio y control
                                </td>
                                <td></td>
                              </tr>

                              <tr>
                                <td class=" text-right" style="width: 200px">
                                  <span class="class_red">*</span>Metodo de valuación:
                                </td>
                                <td style="width: 200px">
                                    PROM. PONDERADO
                                </td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>

                                <td class=" text-right"><span class="class_red">*</span>Costo promedio</td>
                                <td>
                                  <span class="inputpor text-right" 
                                        name="costo_promedio" 
                                        type="text" 
                                        id="costo_promedio" 
                                        style="width: 80px">{{ state.form0?.costo_promedio }}</span>
                                </td>
                              </tr>

                              <tr>

                                <td class=" text-right"><span class="class_red">*</span>Ultimo costo</td>
                                <td>
                                  <input 
                                    class="inputpor text-right" 
                                    name="ultimo_costo" 
                                    type="text" 
                                    v-model="state.form0.ultimo_costo" 
                                    style="width: 80px" 
                                    onkeyup="calcular(this.value); return false;" 
                                    data-valtipo="ND" data-valreq="true">

                                </td>
                                <td class=" text-right"></td>
                                <td>
                                </td>
                              </tr>
                              
                              <tr>

                                <td class=" text-right"><span class="class_red">*</span> Moneda:</td>
                                <td colspan="2" class="text-left">
                                  <NuListRadio
                                    :options="state.divisas"
                                    name="id_moneda"
                                    v-model= "state.form0.id_moneda"
                                    @change=" handleChangeRadioDivisa"
                                    :isHorizontal = "true"
                                  />
                                </td>
          
                              </tr>
          
                              <!-- tabla de precios-->
                              <tr>
                                <td></td>
                                <td style="    padding: 0;" colspan="3">
          
                                  <table class="tabla_plomo">
                                    <tbody><tr>
                                      <td colspan="5" style="background-color:#f1c47a; height:20px">
                                        Tabla de porcentajes y precios
                                      </td>
                                      
                                      
                                    </tr>
          
                                    <tr>
                                      
                                      <td>%Utilidad 1</td>
                                      <td>
                                        <input 
                                          name="util1" 
                                          type="text" 
                                          v-model="state.form0.util1" 
                                          style="width: 80px" 
                                          class="form-control text-right" 
                                          data-valtipo="ND" data-valreq="true" 
                                          onkeyup="calcular_precio(this.value, 'preciov1'); return false;">
                                      </td>
                                      <td class=" text-right"><span class="class_red">*</span>Precio v1</td>
                                      <td>
                                        <input 
                                            class="inputpor text-right" 
                                            name="preciov1" 
                                            type="text" 
                                            style="width: 80px" 
                                            onkeyup="calcular_utilidad(this.value, 'util1'); return false;" 
                                            data-valtipo="ND" data-valreq="true" 
                                            v-model="state.form0.preciov1">
                                      </td>

                                      <td style="color:#b04a4a">Publico en gral</td>

                                    </tr>
                                    <!-- -->
                                    <tr>
                                      
                                      <td>%Utilidad 2</td>
                                      <td>
                                        <input 
                                          name="util2" 
                                          type="text" 
                                          v-model="state.form0.util2" 
                                          style="width: 80px" 
                                          class="form-control text-right" 
                                          data-valtipo="ND" 
                                          data-valreq="true" 
                                          onkeyup="calcular_precio(this.value, 'preciov2'); return false;">
                                      </td>
                                      <td class=" text-right"><span class="class_red">*</span>Precio v2</td>
                                      <td>
                                        <input 
                                          class="inputpor text-right" 
                                          name="preciov2" 
                                          type="text" 
                                          style="width: 80px" onkeyup="calcular_utilidad(this.value, 'util2'); return false;" 
                                          data-valtipo="ND" 
                                          data-valreq="true" 
                                          v-model="state.form0.preciov2"
                                          />
                                      </td>

                                      <td style="color:#b04a4a">Mayorista A</td>
                                    </tr>
          
                                    <tr>
                                      
                                      <td>%Utilidad 3</td>
                                      <td>
                                        <input 
                                          name="util3" 
                                          type="text" 
                                          v-model="state.form0.util3" 
                                          style="width: 80px" 
                                          class="form-control text-right" 
                                          data-valtipo="ND" 
                                          data-valreq="true" 
                                          onkeyup="calcular_precio(this.value, 'preciov3'); return false;">
                                      </td>
                                      <td class=" text-right"><span class="class_red">*</span>Precio v3</td>
                                      <td>
                                        <input 
                                          class="inputpor text-right" 
                                          name="preciov3" 
                                          type="text" 
                                          style="width: 80px" onkeyup="calcular_utilidad(this.value, 'util3'); return false;" 
                                          data-valtipo="ND" data-valreq="true" 
                                          v-model="state.form0.preciov3"
                                          >
                                      </td>
                                      
                                      <td style="color:#b04a4a">Mayoista B</td>
                                    </tr>
          
                                    <tr>
                                      
                                      <td>%Utilidad 4</td>
                                      <td>
                                        <input 
                                          name="util4" 
                                          type="text" 
                                          v-model="state.form0.util4"
                                          style="width: 80px" 
                                          class="form-control text-right" 
                                          data-valtipo="ND" 
                                          data-valreq="true" 
                                          onkeyup="calcular_precio(this.value, 'preciov4'); return false;">
                                      </td>
                                      <td class=" text-right"><span class="class_red">*</span>Precio v4</td>
                                      <td>
                                        <input 
                                          class="inputpor text-right" 
                                          name="preciov4" 
                                          type="text" 
                                          style="width: 80px" 
                                          onkeyup="calcular_utilidad(this.value, 'util4'); return false;" 
                                          data-valtipo="ND" data-valreq="true" 
                                          v-model="state.form0.preciov4">
                                      </td>

                                      <td style="color:#b04a4a">Mayorista C</td>
                                    </tr>
          
                                    
                                  </tbody></table>
                                
                                </td>
                              </tr>
                              <!-- -->
                              

                            </tbody></table>
                          

                          
                        
                          
                            
                              <table class="tabla_basic" style="width: 100%;text-align: left;">
                                <tbody><tr>
                                  <td class="celda-cafe1" colspan="4">
                                    Existencia 
                                  </td>
                                  <td></td>
                                </tr>

                                <tr>
                                  <td colspan="2">

                                    <table id="tabla01" class="table table-sm">
                                      <thead>
                                        <tr>
                                          <th>Almacen</th>
                                          <th>Stock</th>
                                        </tr>
                                      </thead>
                                      
                                      <tbody><tr>
                                    <td>Almacen 1</td>
                                    <td>
                                      <input 
                                        name="canti[]" 
                                        type="number" 
                                        value="0" disabled style="width:100px">
                                    </td>
                                    </tr></tbody>
                                    </table>
            
                                  </td>                
                                </tr>
                              </tbody></table>
                            

                            
                              
                            &nbsp;
                            &nbsp;
                            &nbsp;
                                  
                          

                          
                            &nbsp;
                          

                        

                      
                      <!-------------------------------->
                      <!-- /.col -->
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->
                  </div>
                  <!-- /.box-body -->
                  <div class="box-footer text-right" style="background-color: gainsboro;">
                    <button  name="btn_save" class="btn"
                      @click="aceptar_click"
                      >
                      <img src="~@/assets/img/peq_ok.png"> Guardar
                    </button>

                    <a href="javascript: history.go(-1)" class="btn btn-default">
                      <img src="~@/assets/img/peq_error.png"> Cancelar
                    </a>
                  </div>
                  <!-- fin-->

                </div>
              </form>
            </div>
          </div>
        </div>

        <br>
        <div class="row ">
            
            
            <!-- -->
            <div class="row">
                <div class="col-md-12">
                    <NuAlert ref="nuaRef" />
                </div>
            </div>
        </div>
    </div>



    <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de guardar?"
      icon="fa fa-question-circle"
      @confirm="handleConfirm">
    </NuConfirm>

</template>

<script lang="ts">
import { ref,reactive, computed, onMounted,onActivated } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 
import { useRoute } from 'vue-router';
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 
import NuAlert from '@/components/nubox/NuAlert.vue';
import NuSwitch from '@/components/nubox/NuSwitch.vue';
import NuInputMoney from '@/components/nubox/NuInputMoney.vue';
import NuListRadio from '@/components/nubox/nuListRadio/NuListRadio.vue';
import ItemRadio from '@/components/nubox/nuListRadio/ItemRadio';
import NuCheckBox from '@/components/nubox/NuCheckBox.vue'; 
import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";


const initialForm={
  idd:0,
  ope:'n',
  id_prod:'',

  nombre_p:'',
  descrip:'',
  foto_path1:'',
  id_pclase	:10,
  inventariable_sn:1,
  ultimo_costo	:0,
  costo_promedio:0,
  id_metval	:1,
  stock_max	:0,
  stock_min: 0,
  bool_pvcat:0,
  preciov1	:0,
  preciov2	:0,
  preciov3:0,
  preciov4:0,
  preciov5:0,	
  util1: 0,
  util2: 0,	
  util3: 0,	
  util4: 0,	
  util5: 0,		
  precio_a_distri_sn:0,
  usa_lotes_sn:0,
  cod_barra	:'',
  id_moneda:1,
  ubicacion	:'',
  unidad	:1,
  id_marca	:1,
  id_pcat	:1,
  id_psubcat:1,	
  id_prov:1,	
  id_usu:0,	
  estado:0,
}

export default {
  components: {
    NuConfirm,
    NuAlert,
    ImageUploadSingle,
    NuDropDown,
    NuCheckBox,
    NuSwitch,
    NuInputMoney,
    NuListRadio,
  },
  props: {},
  setup(props:any, context:any){
    const nuaRef = ref(null); //puntero al compoente
    const route = useRoute(); //variables de ruta
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente
    

    const IMAGE_API_URL = ref(process.env.VUE_APP_API_URL + "image_uploadPhoto");
    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "afiles/");

    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      marcas:[],
      producto_categoria_arr:[],
      producto_clase_arr:[],
      proveedor_arr:[],
      unidad_arr:[],
      //selected_estado:0,
      
      estados:[ { value:0, label: "No Activo",color:"#008ffb"  },
                { value:1, label: "Activo", color:"#ce9320" },
              ],
      divisas:[ { value:1, label: "Bs."  },
                { value:2, label: "Sus" },
              ],
    });

    //onMounted(()=>{
    onMounted(()=>{
      
      //console.log(route.params.idd );
      //---cargamos el combo
      cargar_cbo_producto_categoria();
      cargar_cbo_marca();
      cargar_cbo_unidad();
      cargar_cbo_producto_clase();
      cargar_cbo_proveedor();

      //--fecha de inicio
      const date = new Date();
      const fecha_now = date.toLocaleDateString('en-CA');
      console.log("fecha_now: " + fecha_now);

      //state.form0.fecha_reg = fecha_now; 
       
      let idd: number = Number(route.params.idd) ;
      let ope = route.params.ope;

      //state.form0.idd = idd;
      //state.form0.ope = ope;
      
      if(ope=='n'){
        limpiar();
      }else{
      
        editar(idd);
      }

    })
    //----------------------------------
    const limpiar=()=>{
      console.log("limpiar");
      state.form0 = initialForm;
    }
    //-----------------
    const cargar_cbo_marca = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_marca", param).then(res => {
          //if(res.data.length>0){ 
            state.marcas = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    //-----------------
    const cargar_cbo_unidad = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_unidad", param).then(res => {
          //if(res.data.length>0){ 
            state.unidad_arr = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    //-----------------
    const cargar_cbo_producto_categoria = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_producto_categoria", param).then(res => {
          //if(res.data.length>0){ 
            state.producto_categoria_arr = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    //----------------------------
    const cargar_cbo_producto_clase = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_producto_clase", param).then(res => {
          //if(res.data.length>0){ 
            state.producto_clase_arr = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    
    //-----------------
    const cargar_cbo_proveedor = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_proveedor", param).then(res => {
          //if(res.data.length>0){ 
            state.proveedor_arr = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    //--------------------------
    /*const handleCheckboxChange1=(e: Event)=>{
      let ret = e.target as HTMLInputElement
      let sol = ret.checked? 1:0;
      state.form0.usa_lotes_sn = sol;
    }*/
    //--------------------------
    //--------------------------
    const handleCheckboxChange=(e: Event)=>{
      let ret = e.target as HTMLInputElement
      let sol = ret.checked? 1:0;
      state.form0.estado= sol;
    }

    //--------------------------
    const okImage1 = (filename:string ) =>{    
      console.log(filename);
      state.form0.foto_path1 = filename; 
    }

    //--------------------------
    const cancelImage1 = () =>{
      state.form0.foto_path1 = ''; 
    }

    //----------------------------------------------
    function editar(idd: number) {
   
      console.log("editar:" + idd);
      /*let param = { 
        idd: idd,
        ope:'f'
      }*/
      let param={
        id: idd
      }
      console.log(param);
      
      axios.post(process.env.VUE_APP_API_URL  + "productocrud_buscar", param).then(res => {
        console.log(res.data);
        let response = res.data;
          
          if(res.data!==""){ 
              //NotificationManager.success("Correcto");
              Object.assign(state.form0, res.data);
              state.form0.idd= idd;
              state.form0.ope= 'e';
              
              //----
              
              
          }else{
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT
            });
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    }
    //---------------------------------------------
    const aceptar_click = () => {
      console.log("aceptar_click");
      confirmRef.value?.show( 0, ".");
    }

    //--esta es la funcion que llama la confirmacion
    const handleConfirm=(id: number)=>{
        console.log("handleConfirm: " + id);

        save();
    }
    //-------------------------------------------
    //-------------------------------------------
    const handleChangeRadioDivisa=(e:Event)=>{
      let ret = e.target as HTMLInputElement;
      //let sol = ret.checked ? 1 : 0;
      state.form0.id_moneda = parseInt( ret.value);
    }
    //--- item.value, item.label
    const handleChangeDrop=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.estado = item.value;
    }
    
    const handleChangeDropDivisa=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_moneda = item.value;
    }
    const handleChangeDropMarca=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_marca = item.value;
    }
    const handleChangeDropPClase=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_pclase = item.value;
    }
    
    const handleChangeDropProveedor=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_prov = item.value;
    }
    
    const handleChangeDropProdCat=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_pcat = item.value;
    }
    /*---------  ----------------*/
    //==========================================================================
    const save =() =>{

        let param = state.form0;
        console.log(param);
        
        axios.post(process.env.VUE_APP_API_URL  + "productocrud_store", param)
        .then(res => {
            if(res.data.exito === 1){ 
                toast.success(res.data.men);
                console.log("res.data")
                console.log(res.data.data);
                //---ahora actualizamos el context API
                
            }else{
                toast.error(res.data.men);
            }

        }).catch(error => {
            alert("---- Existio un error ---");
            console.log(error);
            
        });
      
    }


    return{
      nuaRef,
      state,
      editar,
      close,
      aceptar_click,
      save,
      handleConfirm,
      confirmRef,
   
      IMAGE_API_URL,
      IMAGE_API_AFILES,
      cargar_cbo_marca,
      cargar_cbo_unidad,
      cargar_cbo_producto_categoria,
      cargar_cbo_producto_clase,
      cargar_cbo_proveedor,
      handleChangeRadioDivisa,
      handleChangeDrop,
      handleChangeDropDivisa,
      handleChangeDropMarca,
      handleChangeDropPClase,
      handleChangeDropProveedor,
      handleChangeDropProdCat,
      handleCheckboxChange,
      
      okImage1,
      cancelImage1,
      
    }
  }
}
</script>
<style >
 

</style>