<template>
  <transition name="dialog"  >
    <div v-show="showModal" class="wrapper">

      <transition name="slide" appear>
        <div v-show="showModal" class="centered" :style="`width:${ancho}`">
          <!--<slot></slot>-->
          <slot />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    showModal: Boolean,
    ancho: { type: String, default: "300px" },
  },
  setup(props, context) {

  }
})
</script>

<style scoped lang="scss">
.wrapper {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.25s;

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.25s;
    background-color: white;
    /* */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}

.dialog-enter-from.wrapper,
.dialog-leave-to.wrapper {
  opacity: 0;

  .centered {
    transform: translate(-50%, -50%) scale(0.5);
  }
}

/************animation************************* */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform .5s;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-50%) translateX(100vw);
}
</style>