<!-- options:[ { value:0, label: "No Confirmado",color:"#008ffb"  },-->
<template>
  <div>
    <div className="side-menur">
          <!--{{ /*JSON.stringify( state.selectedOption )*/ }}
          {{ /*JSON.stringify( options)*/ }}-->
          <ul class="side-menur">
            <li v-for="(item, i) of options" :key="i"      :class="` ${horizontalClass}`">
              
              <!--<i v-if="item.iconColor!=null" class="fa fa-square" :style="`color: ${item?.iconColor}`"></i>&nbsp;-->
              

              <label  class=" "
                      :style="`cursor: pointer; color: ${item.color!=null? item.color:'#2c3e50'}`"
              >
                <input  type="radio" 
                    :name="name"
                    :id="item.value.toString()"
                    :value="item.value"
                    :checked=" String(modelValue) === String(item.value) "
                    @change="(e)=> onClickItem(e, item) " 
                    style="width:auto"
                />&nbsp;{{ item.label }}
              </label>
            </li>
          </ul>
          
    </div>
  </div>
</template>

<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';
import ItemRadio from './ItemRadio';

/*interface ItemRadio {
  value: number,
  label: string,
  color: string,
  //iconColor: string,
}*/

export default defineComponent({
  components: {
    
  },
  props: {
    name:{  type: String, default:'a' },
    modelValue:{type: Number, required:false, default: 0},
    options: {  type: Array as PropType<Array<ItemRadio>>,  required: true, },
    isHorizontal:{type:Boolean, default:false},
  },
  setup(props, {emit}){
    
    /*const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);
    const men = ref('');*/
    const state = reactive({
      selectedOption: "",/* lista de seleccioados */
    })

    // Return classes based on whether item is checked ----------------------
    const isChecked = (item: ItemRadio) =>{
      //return (this.state.selectedOption===String(item.value)) ? "checked-item" : "not-checked-item";
      return ( props.modelValue === item.value ) ? "checked-item" : "not-checked-item";
    }

    const horizontalClass = computed(()=>{
      //return 'background-color: ' + this.hovering ? this.color: 'red';
      //return (props.isHorizontal == true ) ? "d-inline-block" : "";
      return (props.isHorizontal == true ) ? "display_inline" : "";
    });
    /*const setHorizontal =(value: Boolean)=>{
      return (value == true ) ? "d-inline-block" : "";
    }*/

     // Add/Remove checked item from list ------------------------------------
    const handleCheck = (event: Event) => {
      //---actualizamos 
      /*this.setState({
        selectedOption: event.target.value
      });*/
      const target = event.target as HTMLInputElement;
      state.selectedOption =  target.value;
    };

    // Generate string of checked items --------------------------------------
    /*const ToString = () => {
      const selectedOptions = state.selectedOption.length
      ? state.selectedOption.reduce((total, item) => {
          return total + ", " + item;
        })
      : "";
      return selectedOptions;
    }*/
    // ------------------------------------------------------------------------
    const onClickItem = (e: Event,item:any) => {
      const target = e.target as HTMLInputElement;

      target.name = props.name;
      target.value = item.value;
      //this.props.onChange(e);
      //
      //actualiza el v-model="state.selected_item"  que esta afuera
      emit('update:modelValue', item.value);
      emit('onChange', e);
    }; 

    
    
    //--------------------------
    /*const handleConfirm=()=>{
      console.log("handle confirm");
      isModalVisible.value = false;
      emit('confirm',idd.value)
    }*/
    //--------------------------

    return{
      state,
      isChecked,
      //setHorizontal,
      handleCheck,
      //ToString,
      onClickItem,
      horizontalClass,
      
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
.display_inline{
  display:inline;
}
.side-menur{
  padding: 0px;
  margin: 0px;
}
.side-menur li{
  list-style-type: none;
  margin-right:10px;
  padding-bottom: 5px;
}

.side-menur .bloque{
  padding: 5px;
}
.side-menur h3{
  position: relative;
  border-top: 1px solid #dfe1e2;
  line-height: normal;
  color: #1A6986;
  margin: 0;
  padding: 0.5rem 1rem;
  background-color: transparent;
}
.side-menur h3::before {
  background-color: #1A6986;
  border-radius: 99rem;
  content: '';
  display: block;
  position: absolute;
  left: .05rem;
  top: 0.2rem;
  width: 0.25rem;
  height: 90%;
}

.side-menur .d-inline-block {
  display: inline-block !important;
}

/* item ckeched */
.side-menur .checked-item {
  /*color: #1A6986 !important;*/
  font-weight: bold;
}
/* item no seleccionado*/
.side-menur .not-checked-item {
  
  font-weight: normal;
}
</style>
