<!-- options:[ { value:0, label: "No Confirmado",color:"#008ffb"  },-->
<template>
  <span            
      ref="inputRef" 
      :style="style"
      :class="clase">

        {{ state.computedValue }}

  </span>
</template>

<script lang="ts"  >
import { reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted,onBeforeMount,onBeforeUnmount } from 'vue';
import { watch,watchEffect } from 'vue' 
import moment from 'moment'
//import esLocale from "moment/locale/es";
import 'moment/locale/es'  // without this line it didn't work


export default defineComponent({
  components: {

  },
  props: {
    modelValue: { type: String,  },
    name: { type: String,   },
    moneda: { type: String,   },
    style: { type: Object,   default: {}, },
    clase: { type: String,   default: '', },
  },
  setup(props, {emit}) {

    /*const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);*/
    //const inputRef = ref<HTMLInputElement | null>(null);//puntero al componente
    const state = reactive({
      computedValue: '',
    });
    /****************************************************************** */
    onMounted(() => {
      //----aqui la parte de created lo he colocado
      console.log("onMounted");

      /*if (props.modelValue) {
        state.computedValue = formatCurrency( props.modelValue.toString());
      }*/
      //--------------------colocamos un evento---------------
      //window.addEventListener('keyup', up);
    });

    // cada que haya cambios en serverOptions, se actualizan los datos
    watchEffect(()=>{
      
      console.log(props.modelValue);
      if (props.moneda!=="") {
        state.computedValue =   formatCurrency( props.modelValue!.toString()) + ' ' +   props.moneda ;
      }else{
        state.computedValue =   formatCurrency( props.modelValue!.toString());
      }
    });
    
  
    //----------------------------------------------
    
    
    //------------------------------------------------------------------------
    function formatCurrency(value: string) {
      value = parseCurrency(value);
      let newValue;
      let [whole, fraction] = value.split(".");
      // converting whole to number as toLocaleString works with numbers only
      let num:number = parseFloat(whole);
      //if (whole !== "") whole = +whole;
      if (whole !== "") num = +num;
      // if user types a . as the first character
      if (whole === "" && fraction === "") {
        //whole = 0;
        num = 0;
      }
      //.......................
      if (fraction && fraction.length > 2) {
        // fraction can atmost have length 2
        fraction = fraction.substring(0, 2);
      }
      //.......................
      if (fraction) {
        //newValue = whole.toLocaleString("en-US") + "." + fraction;
        newValue = num.toLocaleString("en-US") + "." + fraction;
      } else if (fraction === "") {
        // fraction will be empty string when user enters something like 97.
        //newValue = whole.toLocaleString("en-US") + ".";
        newValue = num.toLocaleString("en-US") + ".";
      } else {
        //newValue = whole.toLocaleString("en-US");
        newValue = num.toLocaleString("en-US");
      }
      if (newValue === "") {
        emit("input", "");
        return "";
      }
      
      emit("input", parseCurrency(newValue));
      //return "$" + newValue;
      return newValue;
    }
    //------------------------------------
    function parseCurrency(value: string) {
      
      value = value.toString();
      // removing everything except \d and \. (numbers and decimal)
      let parsed = value.replace(/[^\d|\.]/g, "");
      return parsed;
    }
    //------------------------------------
    function convertToFloat(val: string) {
        let sol=0;
        
        console.log('ctf', val);
        if (val != '') {
            //--si tiene comas
            console.log("indexOf:" + val.indexOf(','));

            if (val.indexOf(',') !== -1){
                val = val.replace(",", "");
            }

            //------------convertimos a numero
            let val1 = parseFloat(val);
            sol= val1;
        }
        return sol;
    }
    //------------------------------------
    return{
      onMounted,
      state,
      formatCurrency,
      parseCurrency,      
      convertToFloat,
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
 
</style>
