<template>
  <OkCancelBox  :valid="!!text"
                ref="okCancelBoxRef"
                @confirm="handleConfirmBox"
    >
    <template #header>
      <slot name="header"></slot>
    </template>

    <template #body>
      <div style="padding: 10px">
        <span class="p-float-label">
          <label for="username">{{ label }}</label>
          <input  ref="inputRef"  
                  type="text" 
                  v-model="text"
                  autocomplete="off"
                  style="padding-left: 5px;"
                  />
            
        </span>
      </div>
    </template>
    
  </OkCancelBox>
</template>

<script setup lang="ts">
import {defineComponent, ref} from "vue";
import {watchEffect, watch} from "vue";

import OkCancelBox from "./OkCancelBox.vue";
  //---- 
  const props = defineProps({
    label: { type: String,  required: true  },
  })
  //---- 
  const emit = defineEmits(['okDialog'])
  //-------------- state -------------------------

    const okCancelBoxRef = ref<InstanceType<typeof OkCancelBox>>();
    const inputRef = ref<HTMLInputElement | null>(null);
    const text = ref("");
    
    //---------------------------------------
    //-------------------------------
    const show=()=>{
      console.log("show textdialog");
      text.value="";
      
      okCancelBoxRef.value?.show();
      setFocus();
    }
    //-----------------------
    const handleConfirmBox=()=>{
      //inputRef.value?.focus();
      emit('okDialog',text.value);
    }
    //-----------------------
    const setFocus=()=>{
          console.log("setFocus");
          setTimeout(() => {
            if (inputRef.value!=null) {
              console.log("focus");
              //this.$refs.input.focus();
              inputRef.value?.focus();
            }
          }, 500);
    }
    //------------- metodos accesibles desde afuera
    defineExpose({show,});
</script>

<style scoped lang="scss">

</style>