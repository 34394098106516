<template>
  <div id="panel00" class="col-md-12 d-flex flex-row flex-wrap bd-highlight list-item mt-2">

    <div  v-for="(item, i) of items" :key="i" 
          class="card o-hidden bd-highlight m-1" 
          @click="() => add_row(item)">

      <div v-if="item.fileName != ''" class="list-thumb d-flex">
        <img alt="" :src="`${image_PATH_FILES}${item.fileName}`" style="width: 100%;">
      </div>
      <div v-else class="list-thumb d-flex">
        <img alt="" src="~@/assets/img/no_image.png" style="width: 100%;">
      </div>

      <div class="flex-grow-1 d-bock">
        <div class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center"
          style="padding: 0.9rem;">
          <div class="w-40 w-sm-100 item-title">{{ item.nombre }}</div>
          <h6 class="mih6">{{ formatMoney(item.precio) }} {{ item.tag2 }}</h6>
          
          <p class="text-muted text-small w-15 w-sm-100 mb-2">
            cant={{ item.cant }}
            <br>
            id={{ item.id }}
          </p>


          <p class="m-0 text-muted text-small w-15 w-sm-100  d-lg-block item-badges">
            <span v-if="item.cant==0" class="badge badge-danger" style="font-size: 12px;" >Vendido/Arrendado</span>
            <span v-if="item.cant==1" class="badge badge-warning" style="font-size: 12px;">Disponible</span>
            <span v-if="item.estado==2" class="badge badge-seconadary" style="font-size: 12px;">Anulado</span>
          </p>

        </div>
      </div>

    </div>


  </div>
</template>

<script lang="ts"  >
import { ref, reactive, computed, onMounted } from 'vue' 
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import { watch,watchEffect } from 'vue' 

import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import NuListRadio from '@/components/nubox/nuListRadio/NuListRadio.vue';
import ItemRadio from '@/components/nubox/nuListRadio/ItemRadio';
import { toast } from 'vue3-toastify';
import NuLabelMoney from '@/components/nubox/NuLabelMoney.vue'; //

const initialForm = {
  idd: 0,
  ope: 'n',
  id_cliente: 0,
  razon_social: '',
  dir_fiscal: '',
}
//--- lo exportamos para que desde afuera lo puedan declarar
export interface ItemGaleria {
  id: number,
  fileName: string,
  nombre:string,
  descrip:string,
  cant: number,
  precio: number,
  moneda: string,
  estado: number,
  tag: string,
  tag2: string,
}

export default defineComponent({
  components: {
    Modal,
    NuListRadio,
    NuDropDown,
    NuLabelMoney,
  },
  props: {
    title: { type: String, required: false, },
    with_image:{ type: String, default: "auto"},
    items: { type: Array as PropType<ItemGaleria[]>, default: [] },
    image_API_URL:{ type: String, default: ''},
    image_PATH_FILES:{ type: String, default: ''},
  },
  setup(props, context: any) {

    const isModalVisible = ref(false);


    const state = reactive({
      rowList: <Array<ItemGaleria>>([]),   //antes items:[]
      modalInsertar: false,
      form0: initialForm,
      ctipos: [],
      estados: [{ value: 0, label: "En Venta", color: "#5b7282" },
      { value: 1, label: "Vendido", color: "#ff9800" },
      ],
      //selected_estado:0,
      /*estados:[ { value:0, label: "No Confirmado",iconColor:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#2c3e50",iconColor:"#ce9320" },
                { value:2, label: "Hora Cancelada", color:"#2c3e50",iconColor:"#ff4560" },
                { value:3, label: "Atendido", color:"#2c3e50",iconColor:"#00e396" },
              ],*/
    });


    onMounted(() => {

    });
    //-------------------------------------------
    // cada que haya cambios en serverOptions, se actualizan los datos
    watchEffect(()=>{
      
      console.log(props.items);
      
      //state.rowList=  props.items.map(function callback(currentValue, index, array) {
      const userItemsAux = props.items.map((item: ItemGaleria) => {
          const container: ItemGaleria  = {"id":0,"fileName":'',"nombre":'',"descrip":'',
          "cant":0,
            "precio":0,"moneda":'',"estado":0,"tag":'', "tag2":''
          };

          container.id = item.id;
          container.fileName = item.fileName;
          container.nombre = item.nombre;
          container.cant = item.cant;
          const cad:string = formatMoney(item.precio) + " " + item.moneda;
          //container.precio = cad;
          container.estado = item.estado;
          container.tag = item.tag;
          container.tag2 = item.tag2;
          //container.age = item.name.length * 10;

          return container;
      })
      state.rowList= userItemsAux;
      //state.computedValue =   formatCurrency( props.modelValue!.toString()) + ' ' +   props.moneda ;
      /*const totalPrecioUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(totalPrecio);
      state.computedValue = totalPrecioUsd;*/
    });
    //-------------------------------------------
    function formatMoney(num:number) {
      const totalPrecioUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(num);
      //---el resultado viene $12,000
      //--tenemos que quitar el $
      //totalPrecioUsd = totalPrecioUsd.replace('$', '');
      return totalPrecioUsd.replace('$', '');;
    }
    //-------------------------------------------
    function limpiar() {
      state.form0 = initialForm;
    }
    //-------------------------------------------
    const add_row=(item: ItemGaleria)=>{
      context.emit("onItemClic",item);
    }
    //-------------------------------------------
    const formSave = () => {

      //context.emit("okModal",indexTable.value, item);
      let param = state.form0;
      console.log(param);

      axios.post(process.env.VUE_APP_API_URL + "clientecrud_store", param).then(res => {
        let response = res.data;
        console.log("clientecrud_store");
        console.log(res.data);

        if (response.exito === 1) {
          toast.success(response.men);
          //---llamamos a la funcion externa

          //isModalVisible.value = false;
          context.emit("okModal", state.form0);
        } else {

          toast.error(response.men);
        }

      }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request - ");
        console.log(error);

      });
    }
    //-------------------------------------------

    return {
      isModalVisible,
      state,
      formatMoney,
      limpiar,
      add_row,
      formSave,

    };
  },
})
</script>

<style >
.card {
  border-radius: 5px;
  box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
  border: 0;
}
.card.o-hidden {
  /*width: 19%;*/
  max-width: 30%;
  /*height: 195px;*/
  min-height: 300px;
  min-width: 130px;
  /*min-height: 195px;*/
}
.card.o-hidden:hover {
  cursor: pointer;
  border: 1px solid;
}
.item-title {
  /*white-space: nowrap !important;*/
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 150px;
}
.list-item .item-badges {
  position: absolute;
  top: 1px;
}
.list-item .item-badges {
  left: 1px;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-warning {
  color: #212529 !important;
  background-color: #ffc107;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 105%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.mih6{
  font-size: 20px;
  text-align:center;
  color:#222f3e;
  margin:0;
}
</style>
