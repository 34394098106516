<template id="product-list">
  <div>
    <section>
      <div class="actions">
        <!--<router-link :to="{ name: 'plan_nuevo', params: { 'id': 0, 'ope':'n' }}" 
          class="btn btn-success">
          <i class="fa fa-plus"></i> Nuevo
        </router-link>-->
        
        <button class="btn btn-success"
          @click="showModal()" >
          <i class="fa fa-plus"></i> Nuevo 
        </button>

        <!--<button type="button" class="btn btn-primary" 
          @click="isModalVisible = true">Launch demo modal
        </button>

        <button type="button" class="btn btn-info" 
          @click="showConfirmation()">Confirm
        </button>-->

        <button type="button" class="btn btn-default" 
          @click="showPdf()">
          <i class="fa fa-file-pdf" ></i>
          Imprimir
        </button>

        <!--<Modal :title="modalTitle" :body="modalBody" v-if="showModal" @close="showModal = false"></Modal>-->
        <Modal  :showModal="isModalVisible" 
                @closeModal="handleCloseModal">
            <template v-slot:title>Warning</template>
            <p>Do you really wish to clear your cart</p>
        </Modal>
        

      </div>
      <div class="filters row">
        <div class="form-group col-sm-3">
          <label for="search-element">Nombre</label>
          <input v-model="form00.key" class="form-control" requred/>
          <button class="btn btn-info" @click="buscar()" >Buscar</button>
        </div>
      </div>
      <div>
        <div class="table-responsive">
          <table class="tablax tablax-hover">
            <thead>
            <tr>
              <th>Cod. Cta.</th>
              <th>nombre_cuenta</th>
              <th>recibe<br>asientos</th>
              <th>sumar en</th>
              <th>nivel<br> detalle</th>
              <th>grupo<br> cuenta</th>
              
              <th class="col-sm-2">Acciones</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in items" :key="item" 
                v-bind:class="{nivel1: item.nivel_detalle == 1 ,nivel2:item.nivel_detalle == 2}"
              >
              <td >
                <router-link :to="`/plan_nuevo/${item.id_cuenta}/n`">{{ item.id_cuenta }}</router-link>
              </td>
              <td >
                <span v-for="n in item.nivel_detalle" :key="n">&nbsp;</span>
                {{ item.nombre_cuenta }}
              </td>
              <td>{{ item.recibe_asientos }}</td>
              <td>{{ item.sumar_en }}</td>
              <td class="text-center">{{ item.nivel_detalle }}</td>
              <td>{{ item.nombreg }}</td>
              <td>
                <!--<router-link class="btn btn-warning btn-xs" :to="{name: 'marca_nuevo', params: {id: item.id_marca, ope:'e'}}">Editar</router-link>-->
                
                
                <div class="btn-group">
                  <!--<router-link class="btn btn-warning btn-sm" :to="`/plan_nuevo/${item.id_marca}/e`"><i class="fa fa-edit"></i>
                  </router-link>-->
                  <button class="btn btn-warning btn-sm" @click="editar(item.id_cuenta)" >
                    <i class="fa fa-edit"></i>  
                  </button>

                  <a href="#" class="btn btn-danger btn-sm" @click="borrar_click(item)"  ><i class="fa fa-trash"></i></a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <PlanModal 
      ref="planRef"
      @closeModal="closeModal"
      @okModal="okModal">
    </PlanModal>

    <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      @close="handleClose"
      @confirm="handleConfirm">
    </NuConfirm>
    
  </div>
</template>

<script>
import { ref,reactive, computed } from 'vue'
import { onMounted } from 'vue';
//import { computed } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import NuLoading from '@/components/nubox/NuLoading.vue'; //~@ referencia a src
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 
import PlanModal from './PlanModal.vue';
import Modal from "@/components/Common/Modal.vue";

export default {
  
  components: {
    PlanModal,
    NuLoading,    
    Modal,
    NuConfirm,
  },
  setup(){
    
    const isloading = ref(false);
		const isModalVisible = ref(false);
    
    const form00 = reactive({
        key: '',
    });
    var items = ref([
      {id_cuenta: 1, nombre_cuenta: 'Angular'},
      {id_cuenta: 2, nombre_cuenta: 'Ember'},
      {id_cuenta: 3, nombre_cuenta: 'React'}
    ]);

    const planRef = ref(null);//puntero al compoente
    const confirmRef = ref(null);//puntero al compoente

    //-------------------------------------------------
    onMounted(()=>{
      loadData();
      //isModalVisible.value = false;
      //console.log("isModalVisible:"+ isModalVisible.value);
    });

    function showModal(){
      // myModal.value.show();
      console.log("showmodal");
      //isModalVisible.value =true;
      planRef.value.show();
    }
    //----------------------------------------
    function closeModal() {
        console.log("se cerro el modal")
    }
    function okModal() {
        console.log("okModal");
        loadData();

    }
    //----------------------------------------
    const handleCloseModal = (n) => {
      isModalVisible.value = false;
      /*if(n) {
        handleClearLocalstorage();
      }*/
    }

    const handleToggleModal = () => {
      isModalVisible.value = !isModalVisible.value;
    }
    //------------------------------------------------
    const editar=(id)=>{
      planRef.value.editar(id);
    }
    //------------------------------------------------
    const buscar=()=>{
      loadData();
    }
    const borrar_click=(item)=>{
      confirmRef.value.show( item.id_cuenta, item.nombre_cuenta);
    }

    const showConfirmation=()=>{
      confirmRef.value.show(0,'');
    }
    const handleClose=()=>{
      console.log("handleClose");
    }
    const handleConfirm=(id)=>{
      console.log("handleConfirm: " + id);

      planRef.value.eliminar(id);
    }

    const showPdf=()=>{
      //let fileURL = process.env.VUE_APP_API_URL + "plan_printPDF/"+ input;
      let fileURL = process.env.VUE_APP_API_URL + "plan_printPDF";
      let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
      window.open(fileURL,'',windowFeatures);
    }
    
    //----------------------------------------
    function loadData(){
			console.log("loadData");
			isloading.value =true; //muestra cargando
      const param={key: form00.key }
      
			axios.post( process.env.VUE_APP_API_URL + "plan_lista", param)
			.then(res=>{
				isloading.value =false; //quita cargando
				//console.log(res.data);

				items.value = res.data;
				
			})
			.catch(error => {
				isloading.value = false;//quita cargando
			//	this.errorMessage = error.message;
				alert("Hubo un error");
				console.error("Hubo un error!", error);
			});
    }
    
    return{
      isloading,
		  isModalVisible,
      items,
      onMounted,
      loadData,
      form00,
      planRef,
      confirmRef,
      showModal,
      modalTitle: 'Modal Title',
      modalBody: 'Modal Body',
      handleCloseModal,
      handleToggleModal,
      closeModal,
      okModal,
      buscar,
      editar,
      borrar_click,
      showConfirmation,
      handleClose,
      handleConfirm,
      showPdf,

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .danger{
    background-color: #696969;
  }
  .success{
  background-color: #000000;
  }
  .nivel1{
    background-color: #D9C79D; 
  }
  .nivel2{
    background-color: #CAE7DF;  
  }

</style>
