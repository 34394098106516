<template>
  <div>
    <span v-for="(itemRow, index) in matches" :key="index"
          v-bind:class="`badge rounded-pill ${itemRow.clase}`">{{itemRow.label}}</span>
  </div>
</template>

<script lang="ts"  >
import { ref, reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado



//--- lo exportamos para que desde afuera lo puedan declarar
export interface ItemEstado {
  value: number,
  label: string,
  clase: string,
}

export default defineComponent({
  components: {
    
  },
  props: {
    items: { type: Array as PropType<ItemEstado[]>, default: [] },
    value:{ type: Number, default: 0},
  },
  setup(props:any, context: any) {

    //const isModalVisible = ref(false);


    const state = reactive({
     
      //selected_estado:0,
      /*estados:[ { value:0, label: "No Confirmado",iconColor:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#2c3e50",iconColor:"#ce9320" },
                { value:2, label: "Hora Cancelada", color:"#2c3e50",iconColor:"#ff4560" },
                { value:3, label: "Atendido", color:"#2c3e50",iconColor:"#00e396" },
              ],*/
    });


    onMounted(() => {

    });

    //-------------------------------------------
    //--filtramos el array principal en el array de sugerencias
    const matches = computed(() => { 
      return props.items.filter(( item:ItemEstado) => {
          //si lo encontramos retornamos toda la fila 
          return item.value == props.value;
      });  
    });
    //-------------------------------------------
   
    //-------------------------------------------

    return {
      //isModalVisible,
      state,
      matches,
      

    };
  },
})
</script>

<style >

</style>