<template>
    <div>
        <!-- -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/main">
                        <i class="fas fa-home"></i>Inicio
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/inmuebles">Tabla inmuebles</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">usuario</li>
            </ol>
        </nav>
        <!-- -->
        
        {{ JSON.stringify(state.form0) }}
        <!-- -->
        <div>
            <legend style="text-align:center;"><strong>Formulario de Inmueble</strong></legend>
            <div class="form-group" id="picture" style="text-align: center;">

            </div>
        </div>
        
        <div class="row ">
            
            <div class="col-md-12" style="display: flex;align-items: center;justify-content: center;">
                <div class="card mb-3 col-md-7" style="max-width: 600px;">
                    <div class="card-header bg-info">
                        Datos 
                    </div>
                    <div class="card-body">
                        <!---------------------------------------------------->
                        <form id="form0" class="form-horizontal" >
                            <!-- ---------------------------------------------- -->

                            <!--------------------------->
                            <div class="row">
                              <div class="col-md-6 ">
                                <div class="input-group">
                                  <label for="idi" class="col-6 col-form-label">Cod.</label>
                                  <div class="col-6">
                                      <input  name="idi"  
                                              type="text" 
                                              class="form-control" 
                                              v-model="state.form0.id_prod"   readonly disabled>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 " >
                                  <div class="input-group">
                                    <label for="nro_doc" class="col-6 col-form-label">Fecha registro<span
                                            class="text-danger">*</span></label>
                                    <div class="col-6">
                                        <input 
                                            name='fecha_reg' 
                                            type='date' 
                                            class='form-control' 
                                            v-model="state.form0.fecha_reg" 
                                        />
                                    </div>
                                  </div>
                              </div>
                            </div>
                            <!-----------2---------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label"> Nombre:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                  <textarea
                                    name="nombre_p"
                                    type="text"
                                    class="form-control"
                                    rows="2"
                                    v-model = "state.form0.nombre_p"
                                  ></textarea>
                                </div>
                            </div>
                            <!-------------8-------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label"> Descripción:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                  <textarea
                                    name="descrip"
                                    type="text"
                                    class="form-control"
                                    rows="5"
                                    v-model = "state.form0.descrip"
                                  ></textarea>
                                </div>
                            </div>
                            <!-------------7-------------->
                            
                            <!-------------8-------------->
                            <!-------------7-------------->
                            
                            
                            <!--------------------------->
                            <!-------------8-------------->
                            
                            <!--------------------------->
                            
                            <!---->
                            
                            <!--------------------------->
                            
                           
                            <!---->
                            <!--------------------------->
                            <div class=" col-6">
                              <!--
                              <div class="col-md-4">
                                <div class="input-group">
                                  <label class="col-sm-6 control-label">Fondo:</label>
                                  <div class="col-sm-6">
                                    <input  type="text" 
                                            name = "s_fondo" 
                                            class='form-control' 
                                            v-model="state.form0.s_fondo"         
                                    />
                                  </div>
                                </div>
                              </div>
                              -->

                              <div class="col-md-4">
                                

                              </div>
                            </div>
                            <!---->
                            <!-------------10--------------->
                            <div class="top_title_azul1">
                                <h5 class=""><span>Precio</span></h5>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="tel" class="col-sm-3 col-form-label">Precio:</label>
                                <div class="col-sm-3 "> 
                                      <!--<input 
                                        name='precio' 
                                        type='text' 
                                        class='form-control' 
                                        v-model="state.form0.precio" 
                                        style="text-align: right"
                                      />-->
                                      <NuInputMoney
                                        name="preciov1"
                                        placeholder=""
                                        v-model="state.form0.preciov1"
                                        v-bind:style="{textAlign:'right'}"
                                        clase="form-control"
                                      />
                                </div>

                                <label for="tel" class="col-sm-2 col-form-label">Divisa:</label>
                                <div class="col-sm-2 "> 
                                        <NuDropDown
                                            name="id_moneda" 
                                            class="form-select"
                                            :options = "state.divisas"
                                            v-model= "state.form0.id_moneda"
                                            @onChange ="handleChangeDropDivisa"
                                        />
                                </div>
                                
                            </div>
                            <!--<NuSwitch
                              name="divisa"
                              :checked="state.form0.divisa"

                            />-->
                            <!-------------10--------------->
                          
                            <!-------------10--------------->   
                            <!---->
                           
                            
                            <!--Otros-->
                            <div class="top_title_azul1">
                                <h5 class="">Extras</h5>
                            </div>

                            <!--------------aqui------------->
                            
                            <div class="form-group row">
                                <label for="nro_doc" class="col-sm-3 col-form-label">Propietario<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input 
                                        name='propietario' 
                                        type='text' 
                                        class='form-control' 
                                        v-model="state.form0.propietario" 
                                    />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="nro_doc" class="col-sm-3 col-form-label">Financiamiento<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input 
                                        name='financiamiento' 
                                        type='text' 
                                        class='form-control' 
                                        v-model="state.form0.financiamiento" 
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="nro_doc" class="col-sm-3 col-form-label">Obs<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input 
                                        name='obs' 
                                        type='text' 
                                        class='form-control' 
                                        v-model="state.form0.obs" 
                                    />
                                </div>
                            </div>

                            
                            
                            
                            <!------------6--------------->
                            <div class="form-group row">
                                <label for="username" class="col-sm-3 col-form-label">Estado:<span
                                        class="text-danger"></span></label>
                                <div class="col-sm-4">
                                  <NuDropDown
                                      name="total" 
                                      :options = "state.estados"
                                      v-model = "state.form0.estado"
                                      @onChange ="handleChangeDrop"
                                  />

                                </div>
                            </div>
                            <!------------------------------------------------------------->
                            <div class="top_title_azul1">
                                <h5 class="">Fotos</h5>
                            </div>       
                            <!------------6--------------->
                            <div class="row">
                            <div class="col-md-4" style="float: left;">
                              <div card="" class="card portlet light profile-sidebar-portlet bordered">
                                  <div class="card-body">
                                    <ImageUploadSingle  
                                          @okImage= "okImage1"
                                          @cancelImage= "cancelImage1"
                                          :image_API_URL="IMAGE_API_URL"
                                          :image_PATH_FILES = "IMAGE_API_AFILES"
                                          :fileName="state.form0.foto_path1"
                                        />
                                  </div>
                              </div>
                            </div>
                            </div>
                            <!------------------------------------------------------------->
                            <div class="form-group">
                                <div class="text-center">
                                    <button id="btn_save" 
                                        type="button" 
                                        class="btn btn-info" 
                                        @click="aceptar_click">Guardar Datos</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
            <!-- -->
            <div class="row">
                <div class="col-md-12">
                    <NuAlert ref="nuaRef" />
                </div>
            </div>
        </div>
    </div>



    <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de guardar?"
      icon="fa fa-question-circle"
      @confirm="handleConfirm">
    </NuConfirm>

</template>

<script lang="ts">
import { useStore } from 'vuex';
import { IUser } from '@/models/IUser';

import { ref,reactive, computed, onMounted,onActivated } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import router from "@/router";

import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 
import { useRoute } from 'vue-router';
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 
import NuAlert from '@/components/nubox/NuAlert.vue';
import NuSwitch from '@/components/nubox/NuSwitch.vue';
import NuInputMoney from '@/components/nubox/NuInputMoney.vue';

import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";


const initialForm={
  idd:0,
  ope:'n',
  id_prod:0,
  nombre_p: '',
  foto_path1:'',
  descrip: '',
  preciov1:0,
  id_moneda:1,
  propietario: '',
  financiamiento: '',
  fecha_reg: '',
  obs: '',
  id_u:0,
  estado :1,
}

export default {
  components: {
    NuConfirm,
    NuAlert,
    ImageUploadSingle,
    NuDropDown,
    NuSwitch,
    NuInputMoney,
  },
  props: {},
  setup(props:any, context:any){
    const store = useStore(); //para vuex
    const user = ref({} as IUser);

    const nuaRef = ref(null); //puntero al compoente
    const route = useRoute(); //variables de ruta
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente
    

    const IMAGE_API_URL = ref(process.env.VUE_APP_API_URL + "image_uploadPhoto");
    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "afiles/");

    const state = reactive({
      modalInsertar:false,
      form0: Object.assign({}, initialForm ),  //asigna como copia
      roles:[],
      cajas:[],
      //selected_estado:0,
      estados:[ { value:0, label: "Inactivo",color:"#008ffb"  },
                { value:1, label: "Activo", color:"#ce9320" },
              ],
      divisas:[ { value:1, label: "Bs."  },
                { value:2, label: "Sus" },
              ],
    });

    //onMounted(()=>{
    onMounted(()=>{
      
      console.log ("--- onMounted inmueble --");
      user.value = store.getters['auth/getUser']; 
      let id_u = user.value.id_u;
      let username = user.value.username;
      //---cargamos el combo
      //cargar_cbo_rol();

      //--fecha de inicio
      const date = new Date();
      const fecha_now = date.toLocaleDateString('en-CA');
      console.log("fecha_now: " + fecha_now);
      

      state.form0.fecha_reg = fecha_now; 
       
      let idd: number = Number(route.params.idd) ;
      let ope = route.params.ope;
      console.log("ope=>"+ope);
      //state.form0.idd = idd;
      //state.form0.ope = ope;
      
      if(ope=='n'){
        limpiar();
      }else{
        editar(idd);
      }

    })
    //----------------------------------
    const limpiar=()=>{
      console.log("limpiar");
      //state.form0 = initialForm;
      //----es muy importante, asi no mantiene los anteriores valores
      //state.form0 = Object.create(initialForm);
      state.form0 = Object.assign({}, initialForm );  //copia un objeto
      const date = new Date();
      const fecha_now = date.toLocaleDateString('en-CA');
      state.form0.fecha_reg = fecha_now; 
    }
    //-----------------
    const cargar_cbo_rol = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_rol", param).then(res => {
          //if(res.data.length>0){ 
            state.roles = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    //--------------------------
    const handleCheckboxChange=(e: Event)=>{
      let ret = e.target as HTMLInputElement
      let sol = ret.checked? 1:0;
      state.form0.estado= sol;
    }

    //--------------------------
    const okImage1 = (filename:string ) =>{    
      console.log(filename);
      state.form0.foto_path1 = filename; 
    }

    //--------------------------
    const cancelImage1 = () =>{
      state.form0.foto_path1 = ''; 
    }

    //----------------------------------------------
    function editar(idd: number) {
   
      console.log("editar:" + idd);
      /*let param = { 
        idd: idd,
        ope:'f'
      }*/
      let param={
        id: idd
      }
      console.log(param);
      
      axios.post(process.env.VUE_APP_API_URL  + "inmueblecrud_buscar", param).then(res => {
        console.log(res.data);
        let response = res.data;
          
          if(res.data!==""){ 
              //NotificationManager.success("Correcto");
              Object.assign(state.form0, res.data);
              state.form0.idd= idd;
              state.form0.ope= 'e';
              
              //----
              
              
          }else{
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT
            });
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    }
    //---------------------------------------------
    const aceptar_click = () => {
      console.log("aceptar_click");
      confirmRef.value?.show( 0, ".");
    }

    //--esta es la funcion que llama la confirmacion
    const handleConfirm=(id: number)=>{
        console.log("handleConfirm: " + id);

        save();
    }
    //-------------------------------------------
    //--- item.value, item.label
    const handleChangeDrop=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.estado = item.value;
    }
    
    const handleChangeDropDivisa=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_moneda = item.value;
    }
    /*---------  ----------------*/
    //==========================================================================
    const save =() =>{

        let param = state.form0;
        param.id_u= user.value.id_u;  //completamos para enviar 
        console.log(param);
        
        axios.post(process.env.VUE_APP_API_URL  + "inmueblecrud_store", param)
        .then(res => {
            if(res.data.exito === 1){ 
                toast.success(res.data.men);
                console.log("res.data")
                console.log(res.data.data);
                //---ahora actualizamos el context API
                //--redirigimos a ruta tabla inmueble"Producto"
                router.push('/inmuebles');

                
            }else{
                toast.error(res.data.men);
            }

        }).catch(error => {
            alert("---- Existio un error ---");
            console.log(error);
            
        });
      
    }


    return{
      nuaRef,
      state,
      editar,
      close,
      aceptar_click,
      save,
      handleConfirm,
      confirmRef,
   
      IMAGE_API_URL,
      IMAGE_API_AFILES,
      cargar_cbo_rol,
      handleChangeDrop,
      handleChangeDropDivisa,
      handleCheckboxChange,
      okImage1,
      cancelImage1,
      
    }
  }
}
</script>
<style >
 

</style>