import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a70f0f14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-select" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      value: $props.modelValue,
      onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.onChangeOption($event))),
      class: _normalizeClass($setup.computedClasses),
      style: {"padding-left":"5px"}
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, i) => {
        return (_openBlock(), _createElementBlock("option", {
          key: i,
          value: option.value
        }, _toDisplayString(option.label), 9, _hoisted_3))
      }), 128))
    ], 42, _hoisted_2)
  ]))
}