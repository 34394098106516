<template>
	<div class="container-fluid">
		<nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
					<router-link to="/main">
            <i class="fas fa-home"></i>Inicio
          </router-link>
					</li>
        <li class="breadcrumb-item active" aria-current="page">Tabla venta</li>
      </ol>
    </nav>

		<div class="row">
			<div class="col-md-12">
				<h3> Lista de ventas</h3>
			</div>
		</div>
		<!-- form busqueda -->
		<div class="row d-flex justify-content-center">
			<div class=" card_ex" style="padding: 10px; width: 380px; ">

				<form id="form_buscar" v-on:submit="formSubmit">
					<table style="width:100%">
						<tbody>

							<tr>
								<td>Desde:</td>
                <td>Hasta</td>
              </tr>
              <tr>
								<td >
									<input name="fechai" 
                        type="date" 
                        class="form-control" 
                        v-model="state.form0.fechai" >
                </td>
								
                <td>	<input name="fechaf" 
                        type="date" 
                        class="form-control" 
                        v-model="state.form0.fechaf" ></td>
							</tr>
							
              
							<tr>
								
								<td colspan="2" style="text-align: center">
									<button name="btn_buscar" 
                    type="submit" 
                    class="btn btn-info">
										<i class="fa fa-binoculars" ></i> Buscar
									</button>
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<div id="divres1"></div>
								</td>
							</tr>
						</tbody>
					</table>

				</form>
			</div>
		</div>
		<!-- -->
    <router-link :to="`/venta_pos`" class="btn btn-success">
			<i class="fa fa-plus"></i> Nuevo
		</router-link>
		
		

		<div class="card">
			<div class="card-block" style="padding:20px 0 20px 0">
				<div>
					<!-- loading -->
					<!--<div class="loading-mask ">
						<h4 class="loading-content">Loading...</h4>
					</div>-->
					<!-- -->
					<NuLoading :loading="isloading" text="Cargando..." />

					<table class="table table-striped table-hover  table-responsive dt-responsive" cellspacing="0"
						width="100%">
						<thead>
							<tr>
                <th>Cod.</th>
                <th>Fecha reg.</th>
                <th>Cliente</th>

                <th>Total</th>
                <th>Anticipo</th>
                <th>Saldo</th>
                <th>id_u</th>
                <th>Usuario</th>
                <th>Estado</th>

                <th>Accion</th>
							</tr>
						</thead>
						<!-- Loading Icon -->
						<!-- <div id='loading'>
							<i class="fa fa-refresh fa-spin fa-5x fa-fw"></i>
						</div> -->

						<tbody>

              <tr v-for="(item ,index) in miTabla" :key="index">
                <td class="text-left">{{ item.id_v}}</td>
                <td class="text-left">{{ item.fecha_reg}}</td>
                <td class="text-left">{{ item.razon_social}}</td>
                <td class="text-left">{{ item.mtotal}}</td>
                <td class="text-left">{{ item.manticipo}}</td>
                <td class="text-left">{{ item.msaldo}}</td>
                <td class="text-left">{{ item.id_u}}</td>
                <td class="text-left">{{ item.usuario}}</td>
                <td class="text-center">
                <EstadoBadge
                  :items="state.estados"
                  :value="item.estado"
                />
              </td>
 
								<td>
									<!--<button class="btn btn-warning btn-sm" type="button" @click="editarClick(item)">
                    <i class="fas fa-edit"></i>
									</button>-->
                  <button class="btn btn-info btn-sm" 
                          @click="verClick(item)"
                          title="Imprimir"
                          >
                    <i class="fas fa-print"></i> 
                  </button>
                  <router-link :to="`/venta_anular/${item.id_v}`"
                              title="Anular" 
                               class="btn btn-danger btn-sm">
                              <i class="fas fa-trash"></i> 
                  </router-link>

									<!--<button class="btn btn-danger btn-sm" type="button" @click="eliminarClick(item)" >
                    <i class="fas fa-trash"></i>
									</button>-->
								</td>
							</tr>

						</tbody>
					</table>
				</div>

			</div>
		</div>

		<!--<pre>{{tabla_param}}</pre>-->
		<!--<pre>-{.{ isModalVisible }.}</pre>-->

    <!--<NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de eliminar?"
      @confirm="handleConfirm">
    </NuConfirm>-->

	</div>
</template>

<script lang="ts">
//import auth from '../config/auth.js';

import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import NuLoading from '@/components/nubox/NuLoading.vue'; //
import NuConfirm from '@/components/nubox/NuConfirm.vue'; 
import EstadoBadge from '@/components/EstadoBadge.vue'; 
import moment from 'moment'

interface IRow{
  id_v:number,
fecha_reg:string,
razon_social:string,
idi:number,
detallet:string,
mtotal:number,
manticipo:number,
msaldo:number,
id_u:number,
usuario:string,
estado:number,

}

const initialForm={
 
  fechai:'',
  fechaf:'',


}

export default defineComponent({
  components: {    
    NuLoading,
		NuConfirm,
    EstadoBadge,
    
  },
  setup() {

    const isloading = ref(false);
		const isModalVisible = ref(false);
    
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente

    //----objeto reactivo para la parte superior, grupo de variables
    const state = reactive({
      modalInsertar:false,
      form0: Object.assign({},initialForm),
      //selected_estado:0,
      estados:[ { value:0, label: "Pendiente",clase:"bg-warning text-dark"  },
                { value:1, label: "Activo", clase:"bg-success" },
                { value:2, label: "Anulado", clase:"bg-secondary" },
              ],
      param1: '',
      param2: '',
    });

    const key = ref('');
    const miTabla = ref<Array<IRow>>([]);

    const total = computed(() => { return 9;});

    onMounted(()=>{
      //loadData();
      console.log("onMounted:");
      //state.form0.fechai= moment().format("YYYY-MM-DD");
      //state.form0.fechaf= moment().format("YYYY-MM-DD");
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
      state.form0.fechai = startOfMonth;
      state.form0.fechaf = endOfMonth;

      loadData();
    });

    //--------------------------------------------------------------
    function loadData(){
        console.log("loadData");
        isloading.value =true; //muestra cargando
        let param = {
          fechai: state.form0.fechai,
          fechaf: state.form0.fechaf,
        };
        
        axios.post( process.env.VUE_APP_API_URL + "ventacrud_get_list", param)
        .then(res=>{
          isloading.value =false; //quita cargando
          console.log(res.data);

          miTabla.value = res.data;
          
        })
        .catch(error => {
          isloading.value = false;//quita cargando
          alert("Hubo un error");
          console.error("There was an error!", error);
        });
    }

    //---------------------------
    const formSubmit=(e: Event)=>{
      e.preventDefault();
      console.log("formSubmit");
      loadData();
    }

    //---------------------------
    function editarClick ( item: IRow){
      //console.log("editar");
      
      let idd = item.id_v;
      
    }
    function verClick (item:IRow){
      let fileURL = process.env.VUE_APP_API_URL + "ventascrud_reciboPDF/"+item.id_v;
      let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
      window.open(fileURL,'',windowFeatures);
    }
    //---------------------------
    /*function eliminarClick ( item: IRow ){
      
      confirmRef.value?.show( item.id_v, item.detallet );
    }
    //--esta es la funcion que llama la confirmacion
    const handleConfirm=(id: number)=>{
        console.log("handleConfirm: " + id);

     
    }*/
    function closeModal() {
      isModalVisible.value = false;
    }
    //-------------importante
	return {
		isloading,
		isModalVisible,
		closeModal,
		state,
		miTabla,
		total,
    verClick,
    editarClick,
    //eliminarClick,
    //handleConfirm,
    key,
    formSubmit,
    };
  }
			
})
</script>



