<template>
  <teleport to="body">
    <focus-trap v-model:active="active">
      <div
          ref="modal"
          class="modal fade"
          :class="{ show: active, 'd-block': active }"
          tabindex="-1"
          role="dialog"
          :aria-labelledby="`modal-${id}`"
          
      >
        <div  class="modal-dialog modal-dialog-centered" 
              role="document" 
              :style="style"
          >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-dark" :id="`modal-${id}`">
                <slot name="title"></slot>
              </h5>
              <button
                  type="button"
                  class="btn-close header-icon"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="close();"
              >
                <span >&times;</span>
              </button>
            </div>

            <div class="modal__body text-dark">
              <slot></slot>
            </div>

            <div v-if="flagFooter===true" class="modal-footer text-dark">
              <!--<button type="button" class="btn btn-danger" @click="context.emit('closeModal', true)">Yes</button>
              <button type="button" class="btn btn-success" @click="context.emit('closeModal', false)">No</button>
              -->
              <slot name="footer"></slot>
            </div>

          </div>
        </div>
      </div>
    </focus-trap>
    <div class="fade" :class="{ show: active, 'modal-backdrop show': active }"></div>
  </teleport>
</template>

<script>

import { ref, watch} from 'vue'
import { computed} from 'vue' 
//import IdUnique from '../js/utilities/utilities-unique-id';
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'Modal',
  emits: ['closeModal'],
  components: {
    FocusTrap: FocusTrap
  },
  props: {
    showModal: Boolean,
    modalId: String,
    style: {  type: String,  default: '' },
    flagFooter:{ type: Boolean, default: true }
  },
  setup(props, context){
    const id = 5;// IdUnique();
    const active = ref(props.showModal);

    function close() {
        //--evento hacia afuera
        context.emit('closeModal');
    }

    /*const styles = computed(()=>{
      //return 'background-color: ' + this.hovering ? this.color: 'red';
      return props.style;
    });*/
    
    watch(() => props.showModal, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        active.value = props.showModal;
        const body = document.querySelector("body");
        props.showModal ? body.classList.add("modal-open") : body.classList.remove("modal-open")
      }
    },{immediate:true, deep: true});

    return {
      active,
      id,
      close,
      
    }
  }
}
</script>

<style scoped>
.btn-close{
  width: 1.2rem;
  height: 1.2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-size: 25px;
}
.btn-close:enabled:hover
{
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}
.header-icon
{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
</style>