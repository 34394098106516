import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { className: "" }
const _hoisted_2 = { class: "panel_fecha" }
const _hoisted_3 = { class: "text_fecha" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.next(-1)))
      }, "ᐸ"),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.nowMonth), 1),
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next(1)))
      }, "ᐳ")
    ])
  ]))
}