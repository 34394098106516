<template>
	<div class="container-fluid">
		<nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
					<router-link to="/main">
            <i class="fas fa-home"></i>Inicio
          </router-link>
					</li>
        <li class="breadcrumb-item active" aria-current="page">Tabla usuario</li>
      </ol>
    </nav>

		<div class="row">
			<div class="col-md-12">
				<h3> Lista de usuarios</h3>
			</div>
		</div>
		<!-- form busqueda -->
		<div class="row d-flex justify-content-center">
			<div class=" card_ex" style="padding: 10px; width: 380px; ">
				<form id="form_buscar" v-on:submit="formSubmit">
					<table style="width:100%">
						<tbody>

							<tr>
								<td>Nombre:</td>
								<td colspan="2">
									<input name="param1" type="text" class="form-control value=" v-model="key" ></td>
								<td></td>
							</tr>
							
              
							<tr>
								<td></td>
								<td>
									<button id="btn_buscar" name="btn_buscar" type="submit" class="btn btn-default">
										<i class="fa fa-binoculars" ></i> Buscar
									</button>
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<div id="divres1"></div>
								</td>
							</tr>
						</tbody>
					</table>

				</form>
			</div>
		</div>
		<!-- -->
    <router-link :to="`/usuario_nuevo/0/n`" class="btn btn-success">
			<i class="fa fa-plus"></i> Nuevo
		</router-link>
		
		

		<div class="card">
			<div class="card-block" style="padding:20px 0 20px 0">
				<div>
					<!-- loading -->
					<!--<div class="loading-mask ">
						<h4 class="loading-content">Loading...</h4>
					</div>-->
					<!-- -->
					<NuLoading :loading="isloading" text="Cargando..." />

					<table id="userTable" class="table table-striped table-hover  table-responsive dt-responsive" cellspacing="0"
						width="100%">
						<thead>
							<tr>
                <th>Cod.</th>
                <th>Nombre</th>
                <th>Rol</th>
                <th>Telefono</th>
                <th>Email</th>
                <th>Accion</th>
							</tr>
						</thead>
						<!-- Loading Icon -->
						<!-- <div id='loading'>
							<i class="fa fa-refresh fa-spin fa-5x fa-fw"></i>
						</div> -->

						<tbody>

              <tr v-for="(item ,index) in miTabla" :key="index">
								<td>{{item.id_u}}</td>
								<td>{{item.nomape}}</td>
								<td>{{item.rol}}</td>
								<td>{{item.tel}}</td>
								<td class="text-left">{{item.email}}</td>
								<td>
									<!--<button class="btn btn-warning btn-sm" type="button" @click="editarClick(item)"><i
											class="fas fa-edit"></i>
									</button>-->
                  <router-link :to="`/usuario_nuevo/${item.id_u}/e`"  class="btn btn-warning btn-sm">
                    <i class="fas fa-edit"></i> 
                  </router-link>
									<button class="btn btn-danger btn-sm" type="button" @click="eliminarClick(item)" ><i class="fas fa-trash"></i>
									</button>
								</td>
							</tr>

						</tbody>
					</table>
				</div>
				<div class="top">
				</div>

			</div>
		</div>

		<!--<pre>{{tabla_param}}</pre>-->
		<!--<pre>-{.{ isModalVisible }.}</pre>-->

    <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de eliminar?"
      @confirm="handleConfirm">
    </NuConfirm>

	</div>
</template>

<script lang="ts">
//import auth from '../config/auth.js';

import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import NuLoading from '@/components/nubox/NuLoading.vue'; //~@ referencia a src

import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 
import UsuarioModal from './UsuarioModal.vue';


interface IUser{
  id_u: number,
  nro_doc : string ,
  nomape : string,
  username : string,
  email: string,
  tel : string,
  foto_path1 : string,
  id_rol: string,
  estado: number,
}

interface IRow{
  id_u: number, 
  nro_doc: string, 
  nomape: string, 
  email: string,
  username: string,
  tel: string,
  foto_path1: string,
  id_rol: number,
  rol: string,
  estado:number 
}

const initialForm={
  idd:0,
  ope:'n',
  id_cliente:0,
  razon_social:'',
  dir_fiscal:'',
  contacto:'',
  nro_doc : "",
  tel:"",
  id_tdepto:0,
  ciudad: 0,
  obs:'',
}
export default defineComponent({
  components: {    
    NuLoading,
    UsuarioModal,
		NuConfirm,
    
  },
  setup() {
	
		const isloading = ref(false);
		const isModalVisible = ref(false);
    const usuarioModalRef = ref<InstanceType<typeof UsuarioModal>>()
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente

    //----objeto reactivo para la parte superior, grupo de variables
    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      //selected_estado:0,
      estados:[ { value:0, label: "No Confirmado",color:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#ce9320" },
                { value:2, label: "Hora Cancelada", color:"#ff4560" },
                { value:3, label: "Atendido", color:"#00e396" },
              ],
      param1: '',
      param2 : '',
    });

    /*const miTabla = ref([
        { id_u: 1, nro_doc: 'Murphy', nomape: '12', email:'',username:'',tel:'',foto_path1:'',id_rol:0,rol:'',estado:0 },
    ]);*/
    const key = ref('');
    const miTabla = ref<Array<IRow>>([]);
	//---para pagination
	//const pageOfItems= ref([]);

	var total = computed(() => { return 9;});
	
	onMounted(()=>{
		loadData();
		console.log("isModalVisible:"+ isModalVisible.value);
	});

  //--------------------------------------------------------------
	function loadData(){
			console.log("loadData");
			isloading.value =true; //muestra cargando
      let param = {
        param1: state.param1,
      };
      
      
			axios.post( process.env.VUE_APP_API_URL + "usuariocrud_lista", param)
			.then(res=>{
				isloading.value =false; //quita cargando
				console.log(res.data);

				miTabla.value = res.data;
				
			})
			.catch(error => {
				isloading.value = false;//quita cargando
			//	this.errorMessage = error.message;
				alert("Hubo un error");
				console.error("There was an error!", error);
			});
  }

  const formSubmit=(e: Event)=>{
    e.preventDefault();
    console.log("formSubmit");
    loadData();
  }
	/*---------  ----------------*/
	//-- 
	
  function editarClick ( item: IRow){
    //console.log("onClicButton1 -- princi");
    
    let idd = item.id_u;
    usuarioModalRef.value?.editar(idd);
  }
  
  function eliminarClick ( item: IRow ){
    /*console.log("onClicButton2 -- eliminar");
    console.log( item );*/
    confirmRef.value?.show( item.id_u, item.nomape );

  }
	//--esta es la funcion que llama la confirmacion
  const handleConfirm=(id: number)=>{
      console.log("handleConfirm: " + id);

      usuarioModalRef.value?.eliminar(id);
  }
	
	
	function closeModal() {
        isModalVisible.value = false;
  }
	//-------------importante
	return {
		isloading,
		isModalVisible,
		closeModal,
		state,
		miTabla,
		total,
    editarClick,
    eliminarClick,
    handleConfirm,
    key,
    formSubmit,
    };
  }
			
})
</script>