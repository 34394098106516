<template>
  <Box  :showModal="isShow" :ancho="ancho" >
  
    <form class="box1" v-on:submit="formSubmit">
      <div :class="`header ${state.classHeader}`">
        
        <i v-if="state.classIcon!=''" v-bind:class="state.classIcon"></i>
        &nbsp;
        {{ state.title }}
      </div>
      <div class="body">

        {{ state.message }}
      </div>
      <div class="footer1">

        <button v-for="(item, i) of state.buttons" :key="i" 
                :class="`${item.btnClass}`" 
                style="margin-right: 10px"
                @click="()=>item.onClick()"
                >
                {{ item.label }}
        </button>
      </div>
    </form>
  </Box>
</template>

<script setup lang="ts">
import { ref, reactive, computed,defineComponent } from 'vue'
import { watch, watchEffect} from 'vue' 
import Box from "./Box.vue";
import {ItemButton} from "./MessageBoxTypes";


//import {closeDialog} from "../../../lib/index";

  //---- 
  const props = defineProps({
    ancho: { type: String, default: "300px" },
    valid: { type: Boolean, default: true },
  })
  //---- 
  const emit = defineEmits(['close','confirm'])
  //-------------- state -------------------------
  
    const isShow = ref(false);
    const state = reactive({
      title: '',
      message: '',
      classHeader:'',
      
      classIcon:'',
      buttons:[] as ItemButton[],
    })
    //----------------------------------------------
    /*watchEffect(()=>{
      isShow.value = props.showModal;
    });*/
    /*watch(() => props.showModal, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        isShow.value = props.showModal;
        console.log("isShow:"+ isShow.value);
      }
    });*/
    //----------------------------------------------
    const show=(options:{} )=>{
      console.log("show-->");
      console.log("icon->" + options.type);
      
      state.title = options.title;
      state.message = options.message;      
      state.classHeader = options.classHeader;   
      state.classIcon = options.classIcon;   
      state.buttons = options.buttons;
      

      console.log(state);
      //options: merge({}, defaults, MessageBox.defaults || {}, options),
      
      
      //--iniciamos todo vacio
            
      //-------------------------
      /*switch (icon) {
        case MessageBoxIcon.info: state.classIcon='fa fa-info'; break;
        case MessageBoxIcon.success: state.classIcon='fa fa-check'; break;
        case MessageBoxIcon.warning: state.classIcon='fa-solid fa-triangle-exclamation fa-lg fa-fw'; break;
        case MessageBoxIcon.error: state.classIcon='fa fa-times'; break;
        case MessageBoxIcon.none: state.classIcon=''; break;
        default:
          break;
      }*/
      isShow.value = true;
    }
    //-----------------------------------------
    const handleClose=()=>{
      isShow.value = false;
      emit("close");
    }
    //-----------------------------------------
    const formSubmit=(e: Event)=>{
      e.preventDefault();
      isShow.value = false;
      emit('confirm');
    }

    //------------- metodos accesibles desde afuera
    defineExpose({show,});
</script>


<style scoped lang="scss">
.box1 {
  position: relative;
  border-radius: 5px;
  background: #ffffff;
  /*border-top: 3px solid #d2d6de;*/
  margin-bottom: 0px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
 
  .header {
    /*color: #3e3e3e;*/
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    border-radius: 4px;
  }
  .body{
    font-size: 14px;
    padding: 10px;
  }
  .footer1 {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 12px;
    /*background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.06);*/
    margin-top: 0px;
    min-height: 35px;
  }
}
.box_default{
  /*color: #3e3e3e;*/
  background-color: #e7e7e7;
  border-top: 3px solid #d2d6de;
}
.box_primary{
  /*color: #3e3e3e;*/
  //background-color: #edf5ff !important;
  border-top: 3px solid #0344ce;
}
.box_warning{
  //background-color: #fff9e1 !important;
  border-top: 3px solid #f2c21b;
}
.box_success{
  //background-color: #defbe6 !important;
  border-top: 3px solid #28a34b;
}
.box_danger{
  //background-color: #fff1f1 !important;
  border-top: 3px solid #da1b25;
}
.box_white{
  background-color: white !important;
}

</style>