<template>
  <div class="pos_page" style="padding: 10px">

    {{ state.form0  }}

    <div id="mipos" class="container-fluid p-0 app-admin-wrap layout-sidebar-large clearfix">

      <!-- ventana venta rows productos -->
      <div class="row">
        <div class="col-md-5">
          <div class="card card-order">
            <!---->
            <!---->
            <form class="">
              <div class="card-body">
                <!---->
                <!-----------2---------------->
                <div class="form-group row">
                  <label for="nro_doc" class="col-sm-3 col-form-label">Cliente<span class="text-danger">*</span></label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <input name='nro_doc' type='text' class='form-control' v-model="state.form0.cliente" />
                      <button class="input-group-text btn btn-default" @click="btn_search_click">
                        <i class="fa fa-search"></i>
                      </button>

                      <button class="input-group-text btn btn-default" @click="btn_add_click">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>

                </div>

                <!-- -----------8------------ -->
                <div class="row">
                  <div class="col-md-12 text-center">
                      <NuListRadio
                        :options=" state.tipoventa"
                        name="id_estadoc"
                        v-model = "state.form0.id_cp"
                        
                        :isHorizontal = "true"
                      />
                  </div>
                </div>
                
                <!---->
                <div class="row">

                  <div class="mt-2 col-md-12">
                    <div class="pos-detail" style="overflow: auto">
                      <div class="table-responsive">
                        <table id="tabla04" class="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Producto</th>
                              <th scope="col">Precio</th>
                              <th scope="col" class="text-center"><i class="fa fa-trash"></i></th>
                            </tr>
                          </thead>
                          <tbody>


                            <tr v-for="(item, i) of state.rowList" :key="i">
                              <td><span>{{ item.nombre }}</span> <br></td>
                              <td>{{ item.pv }}</td>

                              <td style="text-align:left">
                                <a title="Delete" @click="() => row_delete(i)">
                                  <i class="fa fa-times-circle text-danger" style="font-size:25px"></i>
                                </a>
                              </td>
                            </tr>



                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="footer_panel">
                  <div class="row">



                    <div class="col-sm-12 col-md-6 col-lg-6"><span>
                        <fieldset class="form-group" append="%">
                          <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0" id="__BVID__34__BV_label_">
                            Descuento</legend>
                          <div>
                            <div class="input-group">
                              <!---->
                              <input  type="text" 
                                      v-model="state.form0.descuento" 
                                      class="form-control"
                                      style="text-align:right">

                              <div class="input-group-text" style="background-color: #e3e3e3;">Bs.</div>

                            </div>
                            <div id="Discount-feedback" class="invalid-feedback"></div>
                            <!---->
                            <!---->
                            <!---->
                          </div>
                        </fieldset>
                      </span>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6"><span>
                        <fieldset class="form-group" append="%">
                          <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0" id="__BVID__31__BV_label_">
                            Anticipo</legend>
                          <div>
                            <div role="group" class="input-group">
                              <!---->
                              <input type="text" 
                                v-model="state.form0.manticipo" 
                                class="form-control" 
                                label="Order Tax"
                                style="text-align:right">

                              <div class="input-group-text" style="background-color: #e3e3e3;">Bs.</div>

                            </div>
                            <div id="OrderTax-feedback" class="invalid-feedback"></div>
                            <!---->
                            <!---->
                            <!---->
                          </div>
                        </fieldset>
                      </span>
                    </div>





                    <div class="col-md-12">
                      <div class="">
                        <span> Sub.Total : <span id="t_totalc">{{ state.form0.stotal }}</span> Bs</span>
                      </div>
                      <div class="">
                        <span> Descuento : <span id="t_totalc">{{ state.form0.descuento }}</span> Bs</span>
                      </div>
                      <div class="grandtotal">
                        <span> Total:<span id="t_totalc">&nbsp;&nbsp;{{ state.form0.mtotal }}</span> Bs</span>
                      </div>
                      <div class="">
                        <span> Anticipo : <span id="t_totalc">{{ state.form0.manticipo }}</span> Bs</span>
                      </div>
                      <div class="">
                        <span> Saldo : <span id="t_totalc">{{ state.form0.saldo }}</span> Bs</span>
                      </div>
                    </div>


                    <div class="col-sm-12 col-md-6">
                      <button type="button" class="btn btn-danger ripple btn-rounded btn-block mt-1" @click="limpiar2">

                        Limpiar
                      </button>
                    </div>
                    
                    <div class="col-sm-12 col-md-6">
                      <button type="submit"
                              class="btn btn-primary ripple mt-1 btn-rounded btn-block"
                              @click="aceptar_click">
                        <i class="i-Checkout"></i> Pagar ahora
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </form>
            <!---->
            <!---->
          </div>
        </div>

        <!-- lista de productos -->
        <div class="col-md-7">
          <div class="card list-grid">
            <!---->
            <!---->
            <div class="card-body">
              <!-- -->
              Categorias

              <!-- <a class="menu-item" href="#">Home</a> -->
              <NuMenuChip 
                :options="state.categorias" 
                name="id_estadoc" 
                @change="handleChangeClip" 
                :isHorizontal="true"
                selection="Simple" />

              <!---->

              <!---->
              <div class="row">

                <div class="col-md-12 mt-2">
                  <div class="input-group">
                    <input type="text" 
                      placeholder="Buscar por código o nombre" 
                      class="form-control"
                      @keydown.enter='enter' 
                      v-model="keySearch">

                    <button @click="enter" class="btn btn-default">
                      <i class="fa fa-search" ></i>
                    </button>

                  </div>
                </div>
                <!-- --------------------------------------------- -->
                <!-- galeria de items -->
                <GaleriaP 
                  :items="state.items" 
                  :image_PATH_FILES="IMAGE_API_AFILES" 
                  @onItemClic="handleItemGalery">
                </GaleriaP>

              </div>



            </div>
            <!---->
            <!---->
          </div>
        </div>


      </div>
    </div>

  </div>


  <BoxBuscarCliente ref="boxClienteRef" @okModal="handleOkBox" />

  <ClienteModal2 
    ref="clienteModalRef" 
    @okModal="handleOkBoxCli">
  </ClienteModal2>

  <NuConfirm 
    ref="confirmRef" 
    title="Confirmación" 
    message="¿Esta ud seguro de guardar?" 
    icon="fa fa-question-circle"
    @confirm="handleConfirm">
  </NuConfirm>

  <contado-modal 
  ref="contadoModalRef" 
  @okModal="okModalContado">
  </contado-modal>

  <credito-modal 
    ref="creditoModalRef" 
    @okModal="okModalCredito">
  </credito-modal>
</template>

<script lang="ts"  >
import { useStore } from 'vuex';
import { ref, reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import { watchEffect } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import { toast } from 'vue3-toastify';
import Swal from 'sweetalert2'

import { IUser } from '@/models/IUser';

import Modal from "@/components/Common/Modal.vue";
import NuConfirm from "@/components/nubox/NuConfirm.vue"; //~@
import NuAlert from "@/components/nubox/NuAlert.vue";
import NuListRadio from '@/components/nubox/nuListRadio/NuListRadio.vue';
import ItemRadio from '@/components/nubox/nuListRadio/ItemRadio';

import BoxBuscarCliente from '@/pages/_box/BoxBuscarCliente.vue'; //~@ 
import ClienteModal2 from '@/pages/mantenimiento/cliente/ClienteModal2.vue';

import NuMenuChip from '@/components/nubox/NuMenuChip.vue';
import { ItemChip } from '@/components/nubox/NuMenuChip.vue';

import GaleriaP from '@/components/GaleriaP.vue';
import { ItemGaleria } from '@/components/GaleriaP.vue';
import ContadoModal from './ContadoModal.vue';
import CreditoModal from './CreditoModal.vue';




interface IRow {
  id: number,
  nombre: string,
  cant:number,
  tp:1,  
  pv: number,
  tag: string,
}

const initialForm = {
  idd: 0,
  ope: 'n',
  id_cliente: 1,
  cliente: 'GENERAL',
  fecha: '',

  id_cp: 1,   /*1:conatdo, 2:credito */
  id_tipod: 'V',
  nota: '',

  stotal: 0,
  descuento: 0,
  mtotal: 0,
  manticipo: 0,
  saldo: 0,

  fecha_prox_pago: '',
  id_u: 0,

  t_tabla: '',
}

export default {
  components: {
    NuConfirm,
    NuAlert,
    Modal,
    NuMenuChip,
    GaleriaP,
    BoxBuscarCliente,
    ClienteModal2,
    NuListRadio,
    ContadoModal,
    CreditoModal,
  },
  props: {
    title: { type: String, required: false, },
  },
  setup(props: any, context: any) {

    const store = useStore(); //para vuex
    //const user = ref<IUser>();
    const user = ref({} as IUser);
    //----------------------------

    const isModalVisible = ref(false);
    const keySearch = ref('');
    const confirmRef = ref<InstanceType<typeof NuConfirm>>(); //puntero al componente
    const boxClienteRef = ref<InstanceType<typeof BoxBuscarCliente>>(); //puntero al componente 
    const clienteModalRef = ref<InstanceType<typeof ClienteModal2>>();
    
    const contadoModalRef = ref<InstanceType<typeof ContadoModal>>();
    const creditoModalRef = ref<InstanceType<typeof CreditoModal>>();
      

    const state = reactive({
      modalInsertar: false,
      form0: initialForm,
      medicos: [],
      rowList: <Array<IRow>>([]),   //antes items:[]
      //selected_estado:0,
      categorias: [{ value: 0, label: "TARJETAS", color: "#008ffb" },
      { value: 1, label: "RECARGAS", color: "#ce9320" },
      { value: 2, label: "CHIPS", color: "#ff4560" },
      { value: 3, label: "CHOCOLATES", color: "#00e396" },
      ],
      items: <Array<ItemGaleria>>([]),   //antes items:[]

      tipoventa:[ { value:1, label: "Contado", color:"#373737"  },
              { value:2, label: "Credito",color:"#373737" },
            ],

    });

    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "afiles/");
    //---------------------------------------------------------------------------


    onMounted(() => {
      //--obtenemos el id_u del global
      user.value = store.getters['auth/getUser']; 
      let id_u = user.value.id_u;
      let username = user.value.username;
      console.log("id_u:"+id_u, " username:"+ username);

      cargar_categorias();

      cargarItems(-1);
    });


    function limpiar() {
      state.form0 = initialForm;
    }
    function limpiar2() {
      state.rowList = [];
      state.form0.stotal = 0;
      state.form0.descuento = 0;
      state.form0.manticipo = 0;
      state.form0.saldo = 0;
    }
    //-----------------------------------------
    watchEffect(() => {
      let suma = 0;
      for (let obj of state.rowList) {
        suma = suma + obj.pv;
      }

      let stotal = suma;

      state.form0.stotal = stotal;
      state.form0.mtotal = stotal - state.form0.descuento;
      state.form0.saldo = stotal - state.form0.descuento - state.form0.manticipo;


    });

    //----------------------------------
    const suma_totales = () => {
      let suma = 0;
      for (let obj of state.rowList) {
        suma = suma + obj.pv;
      }
      return suma;
    }
    //-----------------------------------------
    const cargar_categorias = async () => {
      let param = {};

      await axios
        .post(process.env.VUE_APP_API_URL + "cbo_producto_categoria", param)
        .then((res) => {
          //if(res.data.length>0){
          let item0 = { value: -1, label: "--TODOS--", color: "#008ffb" };
          res.data = [item0, ...res.data];
          state.categorias = res.data;
          //}
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    };

    //--------------------------------------
    //----------------------esta es la funcion que llama la confirmacion
    const handleConfirm = (id: number) => {
      console.log("handleConfirm: " + id);

      //formSave();
      isModalVisible.value = true;
    };
    /*---------  ----------------*/
    const btn_search_click = (e: Event) => {
      console.log("btn_search_click");
      e.preventDefault();
      boxClienteRef.value?.show();
    };
    //-------------------------------------
    const btn_add_click = (e: Event) => {
      e.preventDefault();
      clienteModalRef.value?.show();
    };
    //--------------------------
    function handleOkBox(index: number, item: any) {
      console.log(item);
      //---buscamos el row al cual tenemos que cambiar
      console.log(item.razon_social);

      state.form0.id_cliente = item.id_cliente;
      state.form0.cliente = item.razon_social;
      /*let result = form00.miTabla.filter((row) => {
       row.id_cuenta === item.id_cuenta;
    });*/
    }
    //---------------------------
    function handleOkBoxCli(item: any) {
      state.form0.id_cliente = item.id_liente;
      state.form0.cliente = item.razon_social;
    }
    //------------------------------------------
    const closeModal = () => {
      console.log("evento close");
      isModalVisible.value = false;
      //context.emit("closeModal");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };
    const okModalContado=()=>{
      console.log("okModalContado");
      formSave();
    }
    const okModalCredito=()=>{
      console.log("okModalCredito");
      formSave();
    }
    //---------------------------------------------
    const aceptar_click = (e: Event) => {
      e.preventDefault();
      console.log("aceptar_click");
      //confirmRef.value?.show(0, ".");
      if(validacion()==false) return ;

      //----todo bien si llega aqui
      if(state.form0.id_cp==1){
        contadoModalRef.value?.show(state.form0);
      }else{
        creditoModalRef.value?.show(state.form0);
      }
      


      
      
    };

    //----------------------esta es la funcion que llama la confirmacion
    
    //---------------------------------------------------------------------
    function show() {
      //--evento hacia afuera
      console.log("show in planModal___");
      isModalVisible.value = true;
    }

    const handleChangeClip = (item: ItemChip) => {
      console.log(item);
      //-----cargamos los inmuebles ----
      cargarItems(item.value);
    }
    //----------------------------------------------
    /*id: number,
      fileName: string,
      descrip: string,
      precio: number,
      otro: string,*/

    function cargarItems(id_pcat: number) {
      console.log("cargarItems:" + id_pcat);
      let param = {
        param1: id_pcat,
      }
      console.log(param);

      axios.post(process.env.VUE_APP_API_URL + "ventapos_get_data", param).then(res => {
        console.log(res.data);
        let response = res.data;

        if (res.data !== null) {
          //NotificationManager.success("Correcto");
          //Object.assign(state.items, res.data);
          state.items = res.data;
          //state.form0.idd= idd;
          //state.form0.ope= 'e';

          //----
          show();

        } else {
          toast.error("No existe el registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

      }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);

      });
    }

    //----------------------------------------------
    const enter = () => {
      //selection.value = matches.value[current.value];
      console.log(keySearch.value);
      searchItems(keySearch.value);
    };
    //----------------------------------------------
    function searchItems(key: string) {
      console.log("cargarItems:" + key);
      let param = {
        param1: key,
      }
      console.log(param);

      axios.post(process.env.VUE_APP_API_URL + "ventapos_search_items", param).then(res => {
        console.log(res.data);
        let response = res.data;

        if (res.data !== null) {
          //NotificationManager.success("Correcto");
          //Object.assign(state.items, res.data);
          state.items = res.data;
          //state.form0.idd= idd;
          //state.form0.ope= 'e';

          //----
          show();

        } else {
          toast.error("No existe el registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

      }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);

      });
    }
    //-------------------------------------------
    function handleItemGalery(item: ItemGaleria) {
      console.log(item);
      const obj: IRow = { "id": item.id, "nombre": item.nombre, "tp": 1,
                        "cant":1, "pv": item.precio,"tag":"" };
      row_add(obj);
    }

    const row_add = (item: IRow) => {
      ///----------aqui es la magia
      let updatedList = [...state.rowList];
      //lo agregamos
      updatedList = [...state.rowList, item];
      state.rowList = updatedList;
    }

    //------------- indice del arrau
    const row_delete = (idx: number) => {
      let updatedList = [...state.rowList];
      //-- lo quitamos de la lista
      updatedList.splice(idx, 1);

      state.rowList = updatedList;
    }
    //-----------------------------
    const validacion=()=>{
      
      // hacemos una validacion 
      if(state.rowList.length==0){
        toast.error('No existe inmueble a vender');
        return false;
      }
    }
    //-------------------------------------------
    const formSave = () => {
      console.log("formSave");
      //context.emit("okModal",indexTable.value, item);
      

      let param = state.form0;
      param.id_u = user.value?.id_u;
      param.t_tabla = JSON.stringify(state.rowList);
      //--------------------------------
      console.log(param);

      axios.post(process.env.VUE_APP_API_URL + "ventapos_save", param).then(res => {
        let response = res.data;
        console.log("ventapos_save");
        console.log(res.data);

        if (response.exito === 1) {
          //toast.success(response.men);
          //---llamamos a la funcion externa
          closeModal();
          
          Swal.fire({
            title: 'Registrado Correctamente',
            text:   "",
            icon: 'success', //warning
          
          });
          
          limpiar2();
          cargarItems(-1);
          //searchItems(keySearch.value);
          //isModalVisible.value = false;
          //context.emit("okModal", state.form0);
        } else {

          toast.error(response.men);
        }

      }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request - ");
        console.log(error);

      });
    }
    //-------------------------------------------

    return {
      isModalVisible,
      state,
      limpiar,
      limpiar2,
      closeModal,
      show,
      cargarItems,
      formSave,
      handleChangeClip,
      cargar_categorias,
      keySearch,
      searchItems,
      enter,
      IMAGE_API_AFILES,
      row_add,
      row_delete,
      handleItemGalery,

      suma_totales,
      handleOkBox,
      handleOkBoxCli,
      handleConfirm,
      btn_search_click,
      btn_add_click,
      confirmRef,
      boxClienteRef,
      clienteModalRef,
      contadoModalRef,
      creditoModalRef,
      aceptar_click,
      okModalContado,
      okModalCredito,
      validacion,
      

      

    };
  },
}
</script>
<style >
.grandtotal {
  text-align: center;
  height: 40px;
  background-color: #e6e6e6;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 5px;
}</style>
