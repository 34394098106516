<!-- options:[ { value:0, label: "No Confirmado",color:"#008ffb"  },-->
<template>
  <label class="switch">
      <input  :class="classes" 
              type="checkbox" 
              :checked="checked" 
              :name="name" 
              :disabled="disabled" 
              v-model="state.value">
      <span><slot></slot></span>
  </label>
</template>

<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted,onBeforeMount } from 'vue';
import { watch,watchEffect } from 'vue' 
import moment from 'moment'
//import esLocale from "moment/locale/es";
import 'moment/locale/es'  // without this line it didn't work


export default defineComponent({
  components: {

  },
  props: {
    name: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    classes: { type: String, default:''},
    checked: { type: Boolean, default: false },
  },
  setup(props, {emit}) {

    /*const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);
    const men = ref('');*/
    const state = reactive({
      value: false,
    });
    /****************************************************************** */
    onBeforeMount(() => {
      
      state.value = props.checked;
    });
    /****************************************************************** */
    onMounted(() => {
      
      emit('input', state.value)
    });
    //--------------------------------------
    // this won't work because we are passing a number to watch()
    /*watch(state.value, (val: boolean) => {
      emit('input', val);
    })
    watch(props.checked, (val: boolean) => {
      state.value = val;
    })*/
    //------------------------------------------------------------------------
    watchEffect(()=>{
      emit('input', state.value);
    });
    watchEffect(()=>{
      state.value = props.checked;
    });
    //------------------------------------------------------------------------
  
    //------------------------------------
    return{
      onMounted,
      state,
    
      
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  label.switch {
        input[type="checkbox"] {
            display: none;
            &:checked {
                + span {
                    &:before {
                        background-color: rgba(#007FEB, 0.5);
                    }
                    &:after {
                        background-color: #007FEB;
                        transform: translate(80%, -50%);
                    }
                }
            }
            + span {
                position: relative;
                display: inline-block;
                cursor: pointer;
                font-weight: 500;
                text-align: left;
                margin: 0px;
                padding: 0px 44px;
                &:before,
                &:after {
                    content: '';
                    cursor: pointer;
                    position: absolute;
                    margin: 0;
                    outline: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    transition: all 200ms ease-out;
                }
                &:before {
                    left: 1px;
                    width: 34px;
                    height: 14px;
                    background-color: rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                }
                &:after {
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background-color: rgb(166, 164, 164);
                    border-radius: 50%;
                    /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .14), 0 2px 2px 0 rgba(0, 0, 0, .098), 0 1px 5px 0 rgba(0, 0, 0, .084);*/
                }
            }
            &:checked + span &:after {
                transform: translate(80%, -50%);
            }
        }
    }
</style>
