<!-- options:[ { value:0, label: "No Confirmado",color:"#008ffb"  },-->
<template>
  
  <div className=''>    

    <div class="panel_fecha">
      <button class="btn btn-primary" @click="next(-1)">&#5176;</button>
      <span class="text_fecha">{{ nowMonth }}</span>
      <button class="btn btn-primary" @click="next(1)">&#5171;</button>
    </div>

  </div>
  
</template>

<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';
import moment from 'moment'
//import esLocale from "moment/locale/es";
import 'moment/locale/es'  // without this line it didn't work


export default defineComponent({
  components: {

  },
  props: {
    name: { type: String, default: 'a' },
    value: { type: String, default:''},
  },
  setup(props, {emit}) {

    /*const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);
    const men = ref('');*/
    const state = reactive({
      select: 0,
      date: moment(),
      daysWeek: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      daysWeek1: ['LUN.', 'MAR.', 'MIÉ.', 'JUE.', 'VIE.', 'SÁB.', 'DOM'],
    });

    /****************************************************************** */
    onMounted(() => {
      
      /*let todaydate = new Date();
      state.date = todaydate;*/
      //const hoy = moment();
      //state.date = moment().clone();

      //---primer dia del mes
      const mom = moment();
      const monthStart = mom.clone().startOf('month');
      state.date = monthStart;
    });
    //------------------------------------------------------------------------
    const nowMonth = computed(() => {
      
      /*const year = state.date.year();
      const month = state.date.format('MMMM');
      return `${month} ${year !== moment().year() ? year : ''}`;*/
      const now= moment();  //fecha actual
      //const d = new Date('2019/06/01');
      //const d = new Date(state.date);
      const d = state.date;
      let mes = moment(d).format('MMMM');
      //--- Primera letra en mayuscula
      mes = capitalizarPrimeraLetra(mes);
      const anyo = moment(d).year();
      //return `${mes} ${anyo !== moment(now).year() ? anyo : ''}`;
      return `${mes} ${anyo}`;
      
      /*const year = state.date.getFullYear();
      const month = state.date.format('MMMM');
      return `${month} ${year !== moment().year() ? year : ''}`;
      */
      
    });
    //-----------------metodos------------------------------------------
    //--- siguiente mes o anterior mes
    const next=(value:number)=>{
      console.log("state.date");
      /*const d = new Date(state.date);
      const mom = moment(d).add(value, 'month').clone();
      //console.log(res);
      state.data= mom.toDate();*/

      //state.date = state.date.add(value, 'month').clone();

      state.date = state.date.add(value, 'month').clone();
      //let sol = state.date.format("dddd, MMMM Do YYYY, h:mm:ss a");
      let myDate =  state.date.format("YYYY-MM-DD ");

      

      emit('update:modelValue', myDate);
      emit('change', myDate);
    }
    //------------------------------------
    function capitalizarPrimeraLetra(str: String) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    //------------------------------------
    const click=(day:number)=> {
      const before = state.date.isBefore(day, 'month');
      const after = state.date.isAfter(day, 'month');
      if (after || before) {
        next(after ? -1 : 1);
      }
      state.select = day;
    }

    //------------------------------------
    return{
      onMounted,
      state,
      nowMonth,
      
      next,
      
      click,
      capitalizarPrimeraLetra,
      
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
  .panel_fecha{
    /*flex-direction: row;
    display: flex;*/
    display: block;
  }
  .panel_fecha .text_fecha{
    margin-left: 8px;
    margin-right: 8px;
    font-size: 18px;
  }
</style>
