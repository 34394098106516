<template>
  <div>
    <NuModal :showModal="isModalVisible" @closeModal="closeModal"
              title=""
              ancho="auto">
      <!-- body-->
      <div class="form_degrade_naranja" style="width:320px; padding: 5px; float:left">
        <!-- fin div row -->

        <!-- aca el tab -->
        <div class="row form-group" style="">
          <div class="col-md-6">
            <h4>Total</h4>
          </div>
          <div class="col-md-6">
            <h3   style="color: yellow; text-shadow" 
                  class="border-white">Bs. {{ state.form0.mtotal }}</h3>
          </div>
        </div>


        <div class="form_degrade_celeste" style="width:290px; margin-left:20px;margin-top:28px">
          <!--<div class="tab_celeste">
            <div style="font-size:13px; width:100px;  margin-top:5px; text-align:center ">CONTADO</div>
          </div>-->
          <!--fin div celeste -->
          <!--contado -->
          <div  style="padding: 10px">
            
            <div class="row form-group mt-2" >
              <div class="col-md-5">
                Tipo venta:
              </div>
              <div class="col-md-6 ">
                <span v-if="state.form0.id_cp==1">Contado</span> 
                <span v-if="state.form0.id_cp==2">Credito</span> 
              </div>
            </div>

            <div class="row form-group mt-2" >
              <div class="col-md-5">
                Cliente:
              </div>
              <div class="col-md-6 ">
                {{ state.form0.razon_social }}
              </div>
            </div>

            <div class="row form-group mt-2" >
              <div class="col-md-5">
                Anticipo:
              </div>
              <div class="col-md-6 ">
                {{ state.form0.manticipo }}
              </div>
            </div>

            <div class="row form-group mt-2" style="">
              <div class="col-md-5">
                Saldo:
              </div>
              <div class="col-md-6 " >
                {{ state.form0.msaldo }}
                
              </div>
            </div>

          </div>
          

        </div>
        
        <HR/>
          <div class="text-center" style="">
            <button id="b_d2ok" class="btn" @click="okModal"><img src="~@/assets/img/m_ok.png">Aceptar</button>
            &nbsp;&nbsp;&nbsp;
            <button id="b_d2cancel" class="btn" @click="closeModal" ><img src="~@/assets/img/m_cancel.png">Cancelar</button>
          </div>
      </div><!-- fin div row -->
      
    </NuModal>
  </div>
</template>

<script lang="ts"  >
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import NuModal from "@/components/nubox/NuModal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 


const initialForm={
  idd:0,
  ope:'n',
  id_cp:0,
  id_cliente:0,
  razon_social:'',

  stotal:0,
  mdesc:0,
  mtotal:0,
  manticipo:0,
  msaldo:0,
  
}

export default defineComponent({
  components: {
    NuModal,
  },
  props: {
    title: {  type: String,  required: false, },
  },
  setup(_,context:any) {
    
    const isModalVisible = ref(false);
    

    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      m___s:[],
   
      //selected_estado:0,
      e___s:[ { value:0, label: "No Confirmado",color:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#ce9320" },
              ],
    });


    /*const state = reactive({
      modalInsertar:false,
     
    });*/

    onMounted(() => {});

    function limpiar(){
      state.form0 = initialForm; 
    }

    const okModal=()=>{
      isModalVisible.value = false;
      context.emit("okModal");
    }

    const closeModal = () => {
      console.log("evento close");
      isModalVisible.value = false;
      //context.emit("closeModal");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };

    //---------------------------------------------------------------------
    function show(item:any) {
      //--evento hacia afuera
      console.log("show");
      console.log(item);

      isModalVisible.value = true;
      state.form0 = item;
    }

    //-------------------------------------------
    
    return {
      isModalVisible,
      state,
      limpiar,
      okModal,
      closeModal,
      show,
  
      
      
    };
  },
})
</script>
<style >
/*para dialog ventas, compras*/
.form_degrade_naranja {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #d0b8aa;
    /*background-image: -moz-linear-gradient(center top , #a5c3e7, #82a6e2);*/
    border-color: #efaf95 #e09d81 #e09d81 #f1b299;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 3px;
    color: #060606;
    padding: 8px 0;
    /*text-align: center;*/
    color: #000;
}
</style>