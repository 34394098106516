<template>
  <div class="custom-select" >
    
    <select :value ="modelValue" 
            @change="onChangeOption($event)" 
            v-bind:class="computedClasses"
            style="padding-left: 5px;">
      
      <option v-for="(option, i) of options"   :key="i"
           v-bind:value="option.value"   
        >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script lang="ts" >

import { ref, reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';

export interface ItemOption {
  value: number,
  label: string,
}

export default {

  components: {
  },
  props:{
      options: { type: Array as PropType<ItemOption[]>, default: [] },
      modelValue:{type: Number, required:false, default: 0},
      class:{ type: String, default: null},
      clasex:{ type: String, default: null}, //background-color
      
  },
  // selectValue => current selected value
  // options => choices in the dropdown
  setup(props:any, context:any) {

    const open = ref(false);
    
    //--- Metodos ------------ 
    /*const bc = computed(()=>{
      return props.background || ( props.dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)');
    });*/
   
    const computedClasses = computed(() => {
      return props.class;
    });

    //--- item.label, item.value
    function onChangeOption(e:Event) {
        const target = e.target as HTMLInputElement;
        console.log(target.value);
        //---buscamos en el array, para que lo retornemos el row 
        const filtered = props.options.filter( (item:ItemOption) => {
          return item.value.toString() == target.value;
        });
        
        //---lo anexamos para ser retornado
        let obj = filtered[0];
        console.log("---");
        console.log( obj );

        
        //actualiza el v-model="state.selected_item"  que esta afuera
        context.emit('update:modelValue', obj.value );
        // tambien ponemos a disposicion onchange
        context.emit('onChange', obj );
    }
    return {
       //bc,    
       open,
       onChangeOption,
       computedClasses,
    }
  }
  
}
</script>

<style scoped>


.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  /*height: 47px;
  line-height: 47px;*/
}

.custom-select .selected {
  background-color: #0a0a0a;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #ad8225;
  border-left: 1px solid #ad8225;
  border-bottom: 1px solid #ad8225;
  position: absolute;
  background-color: #0a0a0a;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #ad8225;
}

.selectHide {
  display: none;
}
</style>