<template>
  <transition name="modal-animation">
    <div v-show="showModal" class="modalx">
      <transition name="modal-animation-inner">
        <div v-show="showModal" class="modal-inner" :style="{width:ancho}">
          <h5 v-if="title!=''" class="modal-title" style="color: #3c4242;"><b>{{ title }}</b></h5>
          <div @click="close" class="modal-close-icon">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="20" height="20" xml:space="preserve"><path fill="#010110" d="M8.7,7.6c-0.4-0.4-1-0.4-1.4,0C6.9,8,6.9,8.6,7.3,9l11,11l-11,11c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0 l11-11l11,11c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-11-11L32,9c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-11,11L8.7,7.6z" class="st0"></path></svg>
          </div>
          <!-- modal content-->
          <slot/>
          <!-- -->
          
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    ancho: {type: String , default:"250"},
    title: { type: String,  default:''},
  },
  setup(props, {emit}){

    //console.log(props.showModal);

    function close(){
      emit("closeModal");
    }

    return{
      close,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
/*.modal-close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}*/
.modal-close-icon{
  position: absolute;
  top: -13px;
  right: -9px;
  cursor: pointer;
  background-color: #e8e6e6;
  border-radius: 10px;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}
.modalx{
  display:flex;
  justify-content:center;
  align-items:center;
  height: 100vh;
  width:100vw;
  position: fixed;
  top:0;
  left:0;
  background-color: rgba(71, 71, 71, 0.4);
  z-index: 1000;
}
.modal-inner{
  position: relative;
  max-width: 850px;
  width: 80%;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  background-color: #fff;
  padding: 5px 5px ;
}

.modal-inner  i{
    position:absolute;
    top:15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
}
.modal-inner i:hover{
      color: crimson;
}
.modal-animation-enter-active,
.modal-animation-leave-active{
  transition: opacity .3s cubic-bezier(0.52, 0.02, 0.19, 1.02);

}
.modal-animation-enter-from,
.modal-animation-leave-to{
  opacity: 0;
}

.modal-animation-inner-enter-active{
  transition: all 0.3s cubic-beizer(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-inner-leave-active{
  transition: all 0.3s cubic-beizer(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-inner-enter-from{
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to{
  transform: scale(0.8);
}
</style>
