<template>
    <div >
      
       
        <!-- -->
        <div className="row">
            <div className="col-md-4">
                <div style="display: flex">
                    <label>Mostrar&nbsp;</label>
                    <!--<NuDropDown
                        name="id_1" 
                        :options = "options"
                        :v-model= "selectValue"
                        @onChange = "onHandleChangeOpt"  
                    />-->
                    <NuSelectPage 
                        :per_page="serverOptions.pageSettings.per_page" 
                        @onChangeNumRows="onChangeNumRows" 
                        clasex=""
                    >
                    </NuSelectPage>
                    <label>&nbsp;registros</label>
                </div>
            </div>
            <!-- iconos para exportar -->
            <div className="col-md-8 text-end">
                <div className="table_tools float-right text-right" style="display: inline-block, vertical-align: top">
                <button className="btn btn-default buttons-excel" tabIndex="0" title="Export to Excel" style="margin-right: 5px">
                        <span>
                            <i className="fa fa-file-excel" ></i>
                        </span>
                    </button>
                    <button className="btn btn-default buttons-csv" tabIndex="1" title="Export to CSV" style=" margin-right: 5px">
                        <span>
                            <i className="fa fa-file-text" ></i>
                        </span>
                    </button>
                    <button className="btn btn-default buttons-pdf" tabIndex="0" title="Export to PDF" style="margin-right: 5px">
                        <span>
                            <i className="fa fa-print" ></i>
                        </span>
                    </button>
                </div>
            </div>
            <!--  -->
            

            <div class="table-responsive">
              <div :class="class_container">
                <table id="userTable" :class="classes" cellspacing="0" width="100%">
                  <thead>
                    <tr>
                      <th v-for="(item,index) in columns" :key="index">
                        
                        <div v-if="item.sorting">
                          
                            <NuCol  :columnName="item.field"
                                  :sortKey="serverOptions.sort.field" 
                                  @sort_click="sort_click">{{ item.text }}</NuCol>
                          
                        </div>
                        <div v-else>
                          {{ item.text }}
                        </div>

                        
                      </th> 

                    
                    </tr>
                  </thead>
                  <!-- Loading Icon -->
                  <!-- loading -->
                  <NuLoadingT :loading="loading" text="Cargando..."  />
                  <!------>

                  <tbody>
                    
                    <tr v-for="(item,index) in items" :key="index">
                      <!--<td>{{ item }}</td>-->
                      <td v-for="(col, index2) in columns" :key="index2">

                        <NuCell v-if="col.dataType!='custom'"
                            :key="index2"
                            :itemRow="item"
                            :column="col"
                            
                        />
                        <!-- colo actualiza el slot por nombre-->
                        <!-- asi manejamos multiple slot-->
                        <!-- <slot name="title"></slot> -->
                        <slot :name="col.field" v-if="col.dataType=='custom'"  :item="item"></slot>
                      </td>
                      <!--<td>{{item2.id_cliente}}</td>-->
                      
                      <!--<td>
                        <button class="btn btn-info btn-sm" type="button"><i class="fas fa-info-circle"></i>
                        </button>
                        <button class="btn btn-primary btn-sm" type="button" @click="abrirModalEditar(item2)"><i class="fas fa-edit"></i>
                        </button>
                        <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-trash"></i>
                        </button>
                      </td>-->
                    </tr>
                    
                  </tbody>
                
                </table>
              </div>
            </div>
          <div>
              
              <div class="dt_info" >
                {{serverOptions.pageSettings.from}}-{{serverOptions.pageSettings.to}} de {{serverOptions.pageSettings.total}} registros
              </div>
              <NuPagination 
                :totalCount="serverOptions.pageSettings.total" 
                :pageSize ="serverOptions.pageSettings.per_page" 
                :siblingCount="1"
                :currentPage="serverOptions.pageSettings.current_page" 
                @onChangePage="onChangePage"/>
            </div>
            
        </div>
    </div>
</template>

<script>
//import { ref,reactive } from 'vue'
//import { onMounted } from 'vue'
import { ref,reactive, computed, onMounted ,watch } from 'vue'

import NuDropDown from '@/components/nubox/NuDropDown.vue';
import NuCol from '@/components/nubox/grid/NuCol.vue';
import NuCell from '@/components/nubox/grid/NuCell.vue';
import NuLoadingT from '@/components/nubox/grid/NuLoadingT.vue';
import NuSelectPage from '@/components/nubox/grid/NuSelectPage.vue';
import NuPagination from '@/components/nubox/grid/NuPagination.vue';

export default {
  components: {
    NuDropDown,
    NuLoadingT,
    NuCol,
    NuCell,
    NuSelectPage,
    NuPagination,
  },
  props:{
      items:{ type: Array, default: []},
      columns:{ type: Array, default: []},
      loading:{ type: Boolean, default: false},
      classes:{ type: String, default: ""},
      serverOptions:{ type: Object, defautl:{}}, /*desde afuera */
      class_container:{type: String, default: ""},
      
  },
  //setup(props, context) {
  setup(props, context) {

    const selectedOption= ref(null);
    const options = ref([
        {value: 1, label:'10'},
        {value: 2, label:'25'},
        {value: 3, label:'50'},
        {value: 4, label:'100'},
    ]);
    const selectValue= reactive({});

    const serverOptions =reactive({
      pageSettings: {current_page: 1, total: 12, per_page: 10, from: 1,to: 2,},  /*per_page= items_per_page */
      sort: {field: "", dir: "desc",  },
      filter: [{column: '',ope:'=', value: ''}],
    });
    
    //====================================================================================
    // cada que haya cambios en serverOptions, se actualizan los datos
    watch(props.serverOptions, (value) => { 
      //serverOptions = props.serverOptions;
      serverOptions.pageSettings.current_page= props.serverOptions.pageSettings.current_page;
      serverOptions.pageSettings.total= props.serverOptions.pageSettings.total;
      serverOptions.pageSettings.per_page= props.serverOptions.pageSettings.per_page;
      serverOptions.pageSettings.from= props.serverOptions.pageSettings.from;
      serverOptions.pageSettings.to= props.serverOptions.pageSettings.to;
    });
    //====================================================================================
    //--- item.value, item.label
    const onHandleChangeOpt=(item)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        //form00.id_grupoc = item.value;
    }
    //---cambia numero de elementos por pagina 
    function onChangeNumRows(event) {
      console.log(event.target.value);
      //serverOptions.pageSettings.per_page = (event.target.value); //nueva actual pagina
      //serverOptions.pageSettings.current_page = 1;
      // es reactivo tabla_param.per_page= event.targer.value;
      //console.log("tabla_param->")
      //console.log(tabla_param);
      //loadData();
      
      serverOptions.pageSettings.per_page = (event.target.value); //nueva actual pagina

      //console.log(serverOptions);
      context.emit('onChangeServerOptions',serverOptions.pageSettings.current_page,
                                           serverOptions.pageSettings.per_page,
                                           serverOptions.sort );
    }
    //---retornamos la clase segun criterio
    function sortedClass (key) {
      var sol='';
      if(key==""){
        return '';
      }
      /*if(tabla_param.sort===key){
        if(tabla_param.direction=='asc'){
          tabla_param.direction='desc';
          sol= 'sorted asc';
        }else{
          tabla_param.direction='asc';
          sol = 'sorted desc';
        }
      }*/
      return sol;
    }
    function sort_click(columnName, direction){
      console.log("sort_click: ");
      
      /*tabla_param.sort = sortKey;
      tabla_param.direction= direction;
      tabla_param.current_page=1;
      loadData;*/
      console.log(serverOptions.sort);
      serverOptions.sort.field= columnName;
      serverOptions.sort.dir= direction;
      serverOptions.pageSettings.current_page=1;

      console.log(serverOptions);
      context.emit('onChangeServerOptions',serverOptions.pageSettings.current_page,
                                           serverOptions.pageSettings.per_page,
                                           serverOptions.sort );
    }

   
    function onChangePage(page){
      serverOptions.pageSettings.current_page=page;
      context.emit('onChangeServerOptions',serverOptions.pageSettings.current_page,
                                           serverOptions.pageSettings.per_page,
                                           serverOptions.sort );
    }
    //--- Metodos ------------ 
    const bc = computed(()=>{
      return props.background || ( props.dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)');
    });
    

    return {
      selectedOption,
      options,
      selectValue,
      serverOptions,
      onHandleChangeOpt,
      onChangeNumRows,
      sortedClass,
      sort_click,
      onChangePage,
      
      bc,    
    }
  }
  
}
</script>
<style scoped>

.dt_info{
  clear: both;
  float: left;
  padding-top: .755em;
}
    
</style>