<template>
  <Box  :showModal="isShow" :ancho="ancho">
    
    <form class="box" v-on:submit="formSubmit">
      <div class="header">
        <slot name="header"></slot>
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
      <div class="footer">
        
        <button 
                type="submit" 
                class="btn btn-primary " 
                style="margin-right: 10px"
                :disabled="!valid">{{ okLabel }}</button>
        <button 
                type="button" 
                class="btn btn-default " 
                
                @click="handleClose">{{ cancelLabel }}</button>
      </div>
    </form>
  </Box>
</template>

<script lang="ts">
import { ref,reactive, computed,defineComponent } from 'vue'
import { watch, watchEffect} from 'vue' 
import Box from "./Box.vue";
//import {closeDialog} from "../../../lib/index";

export default defineComponent({
  components: {
    Box,
  },
  props: {
    ancho: { type: String, default: "300px" },
    valid: { type: Boolean, default: true },
    okLabel: { type: String, default: 'OK'  },
    cancelLabel: { type: String,  default: 'CANCEL' }
  },
  setup(props,  {emit}) {

      const isShow = ref(false);
    //----------------------------------------------
    /*watchEffect(()=>{
      isShow.value = props.showModal;
    });*/
    /*watch(() => props.showModal, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        isShow.value = props.showModal;
        console.log("isShow:"+ isShow.value);
      }
    });*/
    //----------------------------------------------
    const show=()=>{
      isShow.value = true;
    }
    //-----------------------------------------
    const handleClose=()=>{
      isShow.value = false;
      emit("close");
    }
    /*const handleConfirm=(e: Event)=>{
      e.preventDefault();
      isShow.value = false;
      emit('confirm');
    }*/
    const formSubmit=(e: Event)=>{
      e.preventDefault();
      isShow.value = false;
      emit('confirm');
    }
    return {
      isShow,
      handleClose,
      formSubmit,
      show,
    }
  }
})
</script>

<style scoped lang="scss">
.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 0px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
 
  .header {
    /*color: #3e3e3e;*/
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    font-size: 20px;
  }
  .body{
    font-size: 1.125em;
    padding: 10px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    margin-top: 0px;
  }
}
</style>