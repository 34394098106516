<template>
  <div class="container">
    <input type="hidden" id="id_v" value="{{$id_v}}" />

    <h2>Anular venta</h2>
    
    <div>
      <NuLoading :loading="isloading" text="Cargando..." />
    </div>

    <div class="row justify-content-center">
      <div class="col-md-10">
        <!-- toolbar-->
        <div
          style="width: 100%;  float: left;   background-color: #a4cfb0;  background-image: -moz-linear-gradient(center bottom, #c7defe,   #e7f1fe  );
            border: solid 1px #c0c5ca;
            display: block;
          "
        >
          <div class="buttons">
            <img class="separador" src="~@/assets/img/m_sepini.png" alt="" />

            <a href="#"  class="regular"  @click="buscar_click" >
              <img src="~@/assets/img/m_buscar.png" alt="" />
              Buscar
            </a>

            <img class="separador" src="{{asset('img/m_sepmed.png')}}" alt="" />

            <a href=""  class="regular"   @click="refresh_click" >
              <img src="~@/assets/img/refresh.png" alt="" />
              Recargar
            </a>

            <a href="" class="regular" @click="reimprime_click"  >
              <img src="~@/assets/img/m_printer.png" alt="" />
              Reimprimir
            </a>

            <img class="separador" src="~@/assets/img/m_sepmed.png" alt="" />

            <a href="#"  class="regular"  @click="anular_click"  >
              <img src="~@/assets/img/m_anularv.png" alt="" />
              Anular
            </a>
          </div>
          
        </div>
        <!-- fin toolbar-->

        <div class="">
          <table
            style="background-color: #fff; width: 100%"
            class="tabla_blanca"
            cellpadding="5px"
          >
            <tbody>
              <tr>
                <td class="text-right" width="107">
                  <b>Nº Doc:</b>
                </td>
                <td class="alignLeft" width="136">
                  <label >{{ state.form0.id_v }}</label>
                </td>
                <td class="text-right" width="150"><B>Tipo de Doc:</B></td>
                <td width="105" >
                  <label >{{ state.form0.idtipod }}</label>
                </td>
              </tr>
              <tr>
                <td class="text-right"><b>Fecha reg:</b></td>
                <td class="alignLeft">
                  <label >{{ state.form0.fecha_reg }}</label>
                </td>
                <td class="text-right"><b>Cod. Cli:</b></td>
                <td>
                  <label>{{ state.form0.id_cli }}</label>
                </td>
              </tr>
              <tr>
                <td class="text-right"><b>Condición:</b></td>
                <td class="alignLeft">
                  <label>{{ state.form0.condicion }}</label>
                </td>
                <td class="text-right"><strong>Cliente:</strong></td>
                <td class="alignLeft">
                  <label>{{ state.form0.nom_cli }}</label>
                </td>
              </tr>
              <tr>
                <td class="text-right"><b>Total:</b></td>
                <td class="alignLeft">
                  <label>{{ state.form0.mtotal }}</label>
                </td>
                <td class="text-right"><strong>Estado:</strong></td>
                <td class="alignLeft">
                  <EstadoBadge
                    :items="state.estados"
                    :value="state.form0.estado"
                  />
                </td>
              </tr>

              <tr>
                <td class="text-right"><b>Nota:</b></td>
                <td class="class_left">
                  <label>{{ state.form0.nota }}</label>
                </td>
                <td class="text-right">
                  <span class="text-right"><b></b></span>
                </td>
                <td class="class_left"></td>
              </tr>
              <tr>
                <td colspan="4"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-responsive" style="float: left;width: 100%;">
          <table
            id="tabla01"
            class="tabla_plomo"
            style="background-color: #fff; width: 100%"
            cellpadding="5px"
            align="left"
          >
            <thead>
              <tr>
                <th>Nº</th>
                <th>Cant</th>
                <th>Detalle</th>
                <th>Total</th>
                <th>Estado</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item,index) in state.items" :key="index">
                <td>{{ item.id_detallev }} </td>
                <td>{{ item.cant }} </td>
                <td>{{ item.nombre_a }} </td>
                <td>{{ item.total }} </td>
                <td>{{ item.estado }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- ---------------->

  </div>
  <OkCancelBox
          ref="okCancelBoxRef"
          ancho="380px"
          @confirm="handleConfirmBox"
          okLabel="ACEPTAR"
          cancelLabel="CANCELAR"
          >
          <!-- -->
          <template #header>
            <span class="jconfirm-icon-c"><i class="fa fa-question-circle"></i></span>
            &nbsp;&nbsp;
            <span>
              Borrar
            </span>
          </template>
          <template #body>
            ¿Esta seguro de eliminar este registro?
          </template>
          <!-- -->
  </OkCancelBox>
</template>

<script lang="ts">
// @ is an alias to /src
import { ref, reactive, computed, onMounted } from "vue";
import { defineComponent, PropType } from "vue";
import { useStore } from 'vuex';
import axios from "@/shared/jwtInterceptor"; //axios interceptado
import router from "@/router";
import { useRoute } from 'vue-router';
import { IUser } from '@/models/IUser';

import NuLoading from '@/components/nubox/NuLoading.vue'; 
import EstadoBadge from '@/components/EstadoBadge.vue'; 
import OkCancelBox from '@/components/nubox/dialogs/OkCancelBox.vue';
import { toast } from 'vue3-toastify';
import Swal from "sweetalert2";

const initialForm = {
  idd: 0,
  ope: "n",
  id_v:0,
  fecha_reg:'',
  fecha:'',
  hora:'',
  mtotal:0,
        
  estado:0,
  nota:'',
  condicion:'',
  id_cli:'',
  nom_cli: '',
  idtipod:'',
};
//--- lo exportamos para que desde afuera lo puedan declarar
interface ItemRow {
  id_detallev:number,
  cant: number,
  nombre_a: string,
  total: number,
  estado: number,
}
//==========================================================================
export default {
  name: "Home",
  components: {
    NuLoading,
    EstadoBadge,
    OkCancelBox,
  },
  props: {},
  setup(props: any, context: any) {
    const store = useStore(); //para vuex
    const user = ref({} as IUser);

    const isloading = ref(false);
    const okCancelBoxRef = ref<InstanceType<typeof OkCancelBox>>();
    const route = useRoute(); //variables de ruta

    const state = reactive({
      modalInsertar: false,
      form0: Object.assign({},initialForm),
      roles: [],
      cajas: [],
      selected_item: 0,
      estados:[ { value:0, label: "Pendiente",clase:"bg-warning text-dark"  },
                { value:1, label: "Activo", clase:"bg-success" },
                { value:2, label: "Anulado", clase:"bg-secondary" },
              ],
      test: 10,
      items: [] as ItemRow[],
    });

    //----------------------------------------------------------
    onMounted(()=>{
      user.value = store.getters['auth/getUser']; 
      let id_u = user.value.id_u;
      let username = user.value.username;
      let id_ca = user.value.id_ca;

      let idd: number = Number(route.params.idd) ;
      
      //state.form0.idd = idd;
      //state.form0.ope = ope;
      
      limpiar();
      buscarData(idd);
      
    })

    //---------------------------------------
    function limpiar(){
      state.form0 = Object.assign({}, initialForm );  //copia un objeto
    }
    //----------------------------------------------
    function buscar_click(){
      let x = prompt("Codigo de venta");
      if(x!=null){
        console.log(x);
        buscarData( parseInt(x) );
      }
    }
    //----------------------------------------------
    function buscarData(id:number){
        console.log("buscarData");
        isloading.value =true; //muestra cargando
        
        const param={id: id}
        console.log(param);
        axios.post( process.env.VUE_APP_API_URL + "venta_anular_buscar", param)
        .then(res=>{
            isloading.value =false; //quita cargando
            console.log(res.data);
            if(res.data.exito==1){
                Object.assign(state.form0, res.data);
                state.form0.idd= id;
                state.form0.ope= 'e';
                //---------asignamos el array
                state.items = res.data.data;
            }else{
                toast(res.data.men, { autoClose: 1000, }); // ToastOptions
            }
            
        })
        .catch(error => {
            isloading.value = false;//quita cargando
        //	this.errorMessage = error.message;
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }
    //-----------------------------------------------
    const reimprime_click =()=>{
      let fileURL = process.env.VUE_APP_API_URL + "ventascrud_reciboPDF/"+ state.form0.id_v;
      let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
      window.open(fileURL,'',windowFeatures);
    }
    //-----------------------------------------------
    const anular_click=()=>{
      okCancelBoxRef.value?.show();
    }
    const handleConfirmBox=()=>{
      anularStore(state.form0.id_v);
    }
    //----------------------------------------------
    function anularStore(id:number){
        console.log("anularStore");
        isloading.value =true; //muestra cargando
        
        const param={
            id_v: id,
            motivo:'',
            id_ca : user.value.id_ca,
            id_alma : user.value.id_alma,
        }
        console.log(param);
        axios.post( process.env.VUE_APP_API_URL + "venta_anular_store", param)
        .then(res=>{
            isloading.value =false; //quita cargando
            console.log(res.data.data);
            if(res.data.exito==1){
              Swal.fire({
                title: "Mensaje",
                text: "Procesado con éxito",
                icon: "warning",
              });
            }else{
                toast(res.data.mensaje, { autoClose: 1000, }); // ToastOptions
            }
            
        })
        .catch(error => {
            isloading.value = false;//quita cargando
        //	this.errorMessage = error.message;
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }
    //-----------------------------------------------
    const refresh_click =() =>{}
    const mostrarAlert = () => {
      Swal.fire({
        title: "OPPS",
        text: "wow",
        icon: "warning",
      });
    };

    return {
      isloading,
      state,
      limpiar,
      buscarData,
      mostrarAlert,
      buscar_click,
      reimprime_click,
      anular_click,
      anularStore,
      okCancelBoxRef,
      handleConfirmBox,
      refresh_click,
    };
  },
};
</script>
