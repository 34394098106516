<template>
  <div>
    <Modal :showModal="isModalVisible" @closeModal="closeModal" :flagFooter="false">
      <template v-slot:title>Cargo cliente</template>
      <!-- body-->
     
      {{ JSON.stringify(state.form0) }}
      <form  method='POST'>

        <div class="modal-body p-2 " style="background-color: #a5e7d1; border-color: #a3c2f8 #a3c2f8 #dae3f2 #e9f1ff;">
        
        <div>
          <b>Razon social:</b> <span id="t_rs3" class="text-danger">{{ state.form0.razon_social}}</span>
        </div>
        <!--<b>Saldo:</b> <span id="t_saldo3" style="font-size: 14pt"></span>-->

        <div class="form_degrade_verde" style="margin-top:8px; margin-botoom:8px">

          <table class="tabla_basic">
            
            <tbody><tr>
              <td><b>Documento cxc:</b></td>
              <td colspan="2">
                <span id="t_doc" class="text-danger">{{ state.form0.id_cxc }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <b>Saldo:</b> 
              </td>
              <td><span id="t_rs2" style="font-size: 14pt">{{ state.form0.saldo }}</span></td>
            </tr>

            <tr>
              <td><b>Fecha:</b></td>
              <td colspan="2">
                
                <input name="fecha_pago" 
                      type="date" 
                      v-model="state.form0.fecha">
              </td>
            </tr>

            <!--<tr>
              <td><b>Vencimiento:</b></td>
              <td colspan="2">
                
                <input name="fecha_vence" 
                      type="date" 
                      v-model="state.form0.fecha_vence">
              </td>
            </tr>-->
            

            <tr>
              <td><b>Importe:</b></td>
              <td colspan="2">
                <input name="importe" 
                    type="number" 
                    min="1" 
                    v-model="state.form0.importe" 
                    style="width:100px;">
              </td>
              <td></td>
            </tr>

            <tr>
              <td><b>Concepto:</b></td>
              <td colspan="2">
                <textarea name="concepto3" 
                      rows="3" 
                      cols="20"
                      v-model = "state.form0.concepto"></textarea>
              </td>
            </tr>

          </tbody></table>

        </div>

        <div class="modal-footer">

          <button type="button" class="btn btn btn-primary" @click="okModal">Aceptar</button>
          <button type="button" class="btn btn-orange" @click="closeModal" >Cancelar</button>

        </div>

      </div> 
      </form>
      <!-- -->
      
      <!--<template v-slot:footer>
        <button  class="btn btn-primary" @click="okModal">Guardar</button>
        <button  class="btn btn-default" @click="closeModal">Cancel</button>
      </template>-->

      
    </Modal>
  </div>
</template>

<script lang="ts"  >
import { useStore } from 'vuex';
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import moment from 'moment';
import { IUser } from '@/models/IUser';

import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 


const initialForm={
  idd:0,
  ope:'n',
  
  id_cxc:0,
  id_cliente:0,
  razon_social:'',
  saldo:0,

  fecha:'',
  importe:0,
  concepto:'',
  id_u:1,
  
}

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    title: {  type: String,  required: false, },
  },
  setup(_,context:any) {
    
    const isModalVisible = ref(false);
    const store = useStore(); //para vuex
    //const user = ref<IUser>();
    const user = ref({} as IUser);
    

    const state = reactive({
      modalInsertar:false,
      form0: Object.assign({},initialForm),
      m___s:[],
   
      //selected_estado:0,
      e___s:[ { value:0, label: "No Confirmado",color:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#ce9320" },
              ],
    });


    /*const state = reactive({
      modalInsertar:false,
     
    });*/

    onMounted(() => {
      console.log("onMounted");

      user.value = store.getters['auth/getUser']; 
      let id_u = user.value.id_u;
      let username = user.value.username;
      //let today = moment();
      //let today = moment(new Date()).format('MM/DD/yyyy hh:mm A [GMT]ZZ');
      let today = moment(new Date()).format('YYYY-MM-DD');
      state.form0.fecha = today;

      limpiar();
    });

    function dateNow(){
      return moment(new Date()).format('YYYY-MM-DD');
    }

    function limpiar(){
      state.form0 = Object.assign({},initialForm); 
    }

    const okModal=()=>{
      isModalVisible.value = false;
      //context.emit("okModal");
      formSave();
    }

    const closeModal = () => {
      console.log("evento close");
      isModalVisible.value = false;
      //context.emit("closeModal");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };

    //---------------------------------------------------------------------
    function show(item:any) {
      //--evento hacia afuera
      console.log("show");
      console.log(item);

      limpiar();

      isModalVisible.value = true;
      state.form0.id_cxc = item.id_cxc;
      state.form0.saldo = item.saldo;
      state.form0.fecha = dateNow();
      state.form0.importe = 1;
      state.form0.concepto='';
      state.form0.id_u= item.id_u;
    }
    //-------------------------------------------
    const formSave=()=>{
        let id_u = user.value?.id_u;
        let id_ca = user.value?.id_ca;
        //context.emit("okModal",indexTable.value, item);
        //let param = state.form0;
        const param={
          id_cxc: state.form0.id_cxc,
          fecha_emi:state.form0.fecha,
          id_cliente: state.form0.id_cliente,
          //fecha_vence:state.form0.fecha_vence,
          importe: state.form0.importe,
          concepto:state.form0.concepto,
          id_u: id_u,
        }
        console.log(param);

        axios.post( process.env.VUE_APP_API_URL +"cxc_estadocuenta_save_cargo", param).then(res => {
            let response = res.data;
            console.log("formSave");
            console.log(res.data);

            if(response.exito===1){     
              toast.success( response.mensaje);
              //---llamamos a la funcion externa
              closeModal();
              //this.props.okModal(); //callback

              //isModalVisible.value = false;
              //context.emit("okModal",state.form0);
              context.emit("okModal",state.form0.id_cliente);
            }else{
                
              toast.error( response.men);
            }

        }).catch(error => {
            //if(error.response.status && error.response.status===400)
            alert("Bad Request - ");
            console.log(error);
            
        });
    }
    //-------------------------------------------
    
    return {
      isModalVisible,
      state,
      limpiar,
      okModal,
      closeModal,
      show,
      dateNow,
      
      
    };
  },
})
</script>
<style >
/*para dialog ventas, compras*/
.form_degrade_verde {
  -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #a5e7d1; 
    /*border-color: #a3c2f8 #a3c2f8 #dae3f2 #e9f1ff;*/

    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 3px;
        
    padding: 8px 0;
    /* text-align: center; */
    color: #000;
}
.form_degrade_celeste {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fefefe;
    background-image: -moz-linear-gradient(center top, #fefefe, #d3ebfa);
    border-color: #2a436c #2a436c #2a436c #2a436c;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: #060606;
    padding: 8px 0;
    /* text-align: center; */
    color: #000;
}
</style>