import { renderSlot as _renderSlot, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a59a1572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "dialog" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, {
          name: "slide",
          appear: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              class: "centered",
              style: _normalizeStyle(`width:${_ctx.ancho}`)
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 4), [
              [_vShow, _ctx.showModal]
            ])
          ]),
          _: 3
        })
      ], 512), [
        [_vShow, _ctx.showModal]
      ])
    ]),
    _: 3
  }))
}