import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-697c2214"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "body" }
const _hoisted_3 = { class: "footer" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, {
    showModal: _ctx.isShow,
    ancho: _ctx.ancho
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "box",
        onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.formSubmit && _ctx.formSubmit(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "body", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary",
            style: {"margin-right":"10px"},
            disabled: !_ctx.valid
          }, _toDisplayString(_ctx.okLabel), 9, _hoisted_4),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-default",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
          }, _toDisplayString(_ctx.cancelLabel), 1)
        ])
      ], 32)
    ]),
    _: 3
  }, 8, ["showModal", "ancho"]))
}